type Props = {
  className?: string;
};

export default function MatchedCar(props: Props) {
  return (
    <span className={props.className}>
      <svg width="16" height="17" viewBox="0 0 8 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.83296 6.15026C7.4775 6.15026 8 5.59753 8 4.91571C8 4.23388 7.4775 3.68115 6.83296 3.68115C6.18841 3.68115 5.66591 4.23388 5.66591 4.91571C5.66591 5.59753 6.18841 6.15026 6.83296 6.15026ZM3.0984 10.101C3.64302 10.101 4.82563 9.90344 5.19909 9.11332C5.57254 8.32321 5.82151 7.63185 5.89931 7.38494C6.36613 6.3973 7.29977 6.3973 7.53318 7.38494C7.77706 8.41691 6.75515 10.1009 6.13272 10.8417C5.74371 11.2532 4.68559 12.0268 3.56522 11.8293C2.44486 11.6318 2.08696 12.0762 1.93135 12.3231L1.23113 13.3108C0.764308 13.8046 0.297493 13.8046 0.0640809 13.3108C-0.207574 12.7361 0.453096 11.6647 0.764309 11.3355C0.997718 10.924 1.79131 10.101 3.0984 10.101Z"
          fill="#686868"
        />
      </svg>
    </span>
  );
}
