import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import {
  ErrorResponse,
  RakutenMembershipInfo,
  LicenseImage,
  LicenseInfo,
  EmergencyInfo,
  NewMembershipInfo,
  Step3Response,
} from 'Membership-Types';
import * as actions from './actions';
import { LoginStatus } from 'Login-Types';

export const isFetching = createReducer(false as boolean)
  .handleAction([actions.fetchUserInfoAsync.request], () => true)
  .handleAction(
    [actions.fetchUserInfoAsync.success, actions.fetchUserInfoAsync.failure],
    () => false,
  );

export const membershipInfo = createReducer({} as RakutenMembershipInfo)
  .handleAction(actions.fetchUserInfoAsync.success, (state, action) => action.payload)
  .handleAction(actions.clearMembershipInfo, (state, action) => action.payload);
export const error = createReducer({} as ErrorResponse).handleAction(
  actions.fetchUserInfoAsync.failure,
  (state, action) => action.payload,
);

export const isChecking = createReducer(false as boolean)
  .handleAction([actions.checkUserInfoAsync.request], () => true)
  .handleAction(
    [actions.checkUserInfoAsync.success, actions.checkUserInfoAsync.failure],
    () => false,
  );

export const step2Error = createReducer({} as ErrorResponse).handleAction(
  actions.checkUserInfoAsync.failure,
  (state, action) => action.payload,
);

export const isSaving = createReducer(false as boolean)
  .handleAction([actions.saveUserInfoAsync.request], () => true)
  .handleAction(
    [actions.saveUserInfoAsync.success, actions.saveUserInfoAsync.failure],
    () => false,
  );

export const step3Error = createReducer({} as ErrorResponse).handleAction(
  actions.saveUserInfoAsync.failure,
  (state, action) => action.payload,
);

const newUserInfo = createReducer({} as NewMembershipInfo)
  .handleAction(actions.updateUserInfo, (state, action) => {
    return { ...state, ...action.payload };
  })
  .handleAction(actions.clearUserInfo, (state, action) => action.payload);

const licenseImage = createReducer({} as LicenseImage)
  .handleAction(actions.updateLicenseImage, (state, action) => {
    return { ...state, ...action.payload };
  })
  .handleAction(actions.clearLicenseImage, (state, action) => action.payload);

const licenseInfo = createReducer({
  licenseExpiryYearType: '1',
  licenseNumber1: '',
  licenseNumber2: '',
  licenseNumber3: '',
} as LicenseInfo)
  .handleAction(actions.updateLicenseInfo, (state, action) => {
    return { ...state, ...action.payload };
  })
  .handleAction(actions.clearLicenseInfo, (state, action) => action.payload);

const emergencyInfo = createReducer({
  sameAsContactFlg: false,
  emergencyTel1: '',
  emergencyTel2: '',
  emergencyTel3: '',
} as EmergencyInfo)
  .handleAction(actions.updateEmergencyInfo, (state, action) => {
    return { ...state, ...action.payload };
  })
  .handleAction(actions.clearEmergencyInfo, (state, action) => action.payload);

export const getStatus = createReducer({} as LoginStatus).handleAction(
  actions.getMemberShipLoginStatusAsync.success,
  (state, action) => {
    return action.payload;
  },
);
export const getStatusError = createReducer({} as ErrorResponse).handleAction(
  actions.getMemberShipLoginStatusAsync.failure,
  (state, action) => action.payload,
);

export const getStep3Response = createReducer({} as Step3Response).handleAction(
  actions.saveUserInfoAsync.success,
  (state, action) => {
    return action.payload;
  },
);

const membershipReducer = combineReducers({
  isFetching,
  membershipInfo,
  error,
  isChecking,
  step2Error,
  isSaving,
  step3Error,
  newUserInfo,
  licenseImage,
  licenseInfo,
  emergencyInfo,
  getStatus,
  getStatusError,
  getStep3Response,
});

export default membershipReducer;
export type MembershipState = ReturnType<typeof membershipReducer>;
