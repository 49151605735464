import { ErrorResponse, ReceiptInfoResponse } from 'Receipt-Types';
import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { fetchReceiptInfoAsync } from './actions';
import * as actions from './actions';

export const isFetchingReceiptInfo = createReducer(false as boolean)
  .handleAction([fetchReceiptInfoAsync.request], () => true)
  .handleAction([fetchReceiptInfoAsync.success, fetchReceiptInfoAsync.failure], () => false);
export const fetchingReceiptInfoSuccess = createReducer({} as ReceiptInfoResponse).handleAction(
  fetchReceiptInfoAsync.success,
  (_state, action) => action.payload,
);

export const fetchingReceiptInfoFailure = createReducer({} as ErrorResponse).handleAction(
  fetchReceiptInfoAsync.failure,
  (_state, action) => action.payload,
);

const setReceiptInfo = createReducer({} as ReceiptInfoResponse).handleAction(
  actions.setReceiptInfo,
  (_state, action) => action.payload,
);

const receiptReducer = combineReducers({
  isFetchingReceiptInfo,
  fetchingReceiptInfoSuccess,
  fetchingReceiptInfoFailure,
  setReceiptInfo,
});

export default receiptReducer;
export type ReservationStepState = ReturnType<typeof receiptReducer>;
