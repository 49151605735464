type Props = {
  className?: string;
};
export default function Login(props: Props) {
  return (
    <span className={props.className}>
      <svg
        width="22"
        height="20"
        viewBox="0 0 22 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.75 0.25H20C20.828 0.25 21.5 0.922 21.5 1.75V18.25C21.5 19.078 20.828 19.75 20 19.75H8.75C7.922 19.75 7.25 19.078 7.25 18.25V12.25H8.75V18.25H20V1.75H8.75V7.75H7.25V1.75C7.25 0.922 7.922 0.25 8.75 0.25ZM16.25 10L11 13.75V10.75H0.5V9.25H11V6.25L16.25 10Z"
          fill="#686868"
        />
      </svg>
    </span>
  );
}
