type Props = {
  className?: string;
  color?: string;
};
export default function DownloadIcon(props: Props) {
  const { className, color = 'white' } = props;
  return (
    <span className={className}>
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.5 10.5V13H2.5V10.5H1.5V13C1.5 13.55 1.95 14 2.5 14H14.5C15.05 14 15.5 13.55 15.5 13V10.5H14.5Z"
          fill={color}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.863 11.3385L12.5 7.5H10.5V3.5H6.5V7.5H4.5L8.126 11.3375C8.3235 11.554 8.6645 11.5545 8.863 11.3385Z"
          fill={color}
        />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.5 2.5H10.5V1.5H6.5V2.5Z" fill={color} />
      </svg>
    </span>
  );
}
