type Props = {
  className?: string;
};

export default function CheckMark(props: Props) {
  return (
    <span className={props.className}>
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.5 20C16.0228 20 20.5 15.5228 20.5 10C20.5 4.47715 16.0228 0 10.5 0C4.97715 0 0.5 4.47715 0.5 10C0.5 15.5228 4.97715 20 10.5 20Z"
          fill="#BF0000"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.3333 6.42266L15.7442 5.8335L8.41667 13.1606L5.25584 10.0002L4.66667 10.5893L8.41667 14.3393L16.3333 6.42266Z"
          fill="white"
        />
      </svg>
    </span>
  );
}
