import logo from 'icons/svg/general/logo.svg';

type Props = {
  className?: string;
  alt?: string;
};
// Because svg file cann't put in <span></span>, use svg file by <img>.
export default function Logo(props: Props) {
  const { className, alt } = props;
  return <img src={logo} alt={alt ? alt : ''} className={className}></img>;
}
