type Props = {
  className?: string;
  color?: string;
};
export default function DropdownArrow(props: Props) {
  const { color = '#606060' } = props;
  return (
    <span className={props.className}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M8.00005 11.75L12.3302 4.25H3.66992L8.00005 11.75Z" fill={color} />
      </svg>
    </span>
  );
}
