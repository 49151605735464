type Props = {
  className?: string;
  color?: string;
};
export default function PdfDownload(props: Props) {
  const { color = '#333333', className = '' } = props;
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M13 14H3V2H9V1H3C2.73478 1 2.48043 1.10536 2.29289 1.29289C2.10536 1.48043 2 1.73478 2 2V14C2 14.2652 2.10536 14.5196 2.29289 14.7071C2.48043 14.8946 2.73478 15 3 15H13C13.2652 15 13.5196 14.8946 13.7071 14.7071C13.8946 14.5196 14 14.2652 14 14V6H13V14Z"
        fill={color}
      />
      <path
        d="M11 5H14L10 1V4C10 4.26522 10.1054 4.51957 10.2929 4.70711C10.4804 4.89464 10.7348 5 11 5Z"
        fill={color}
      />
      <path
        d="M4 10.3449V7.09988H5.09C5.32484 7.09571 5.55802 7.13996 5.775 7.22988C5.95907 7.30716 6.11579 7.43776 6.225 7.60488C6.33445 7.7837 6.39 7.99029 6.385 8.19988C6.39057 8.40954 6.33498 8.61629 6.225 8.79488C6.11579 8.96199 5.95907 9.0926 5.775 9.16988C5.55876 9.26226 5.32505 9.30662 5.09 9.29988H4.685V10.3449H4ZM5.055 7.69988H4.685V8.69988H5.055C5.21533 8.70703 5.37404 8.66517 5.51 8.57988C5.56947 8.53701 5.61683 8.47949 5.6475 8.4129C5.67816 8.34632 5.69108 8.27293 5.685 8.19988C5.69059 8.12688 5.67745 8.05366 5.64683 7.98716C5.6162 7.92066 5.56911 7.86308 5.51 7.81988C5.37404 7.73459 5.21533 7.69273 5.055 7.69988Z"
        fill={color}
      />
      <path
        d="M6.74023 10.3452V7.10015H7.90023C8.19285 7.09273 8.48236 7.16166 8.74023 7.30015C8.98211 7.42717 9.18187 7.62172 9.31523 7.86015C9.45012 8.12676 9.5204 8.42136 9.5204 8.72015C9.5204 9.01894 9.45012 9.31354 9.31523 9.58015C9.18217 9.82008 8.98246 10.0163 8.74023 10.1452C8.48236 10.2836 8.19285 10.3526 7.90023 10.3452H6.74023ZM7.84523 7.71515H7.42523V9.71515H7.84523C7.97588 9.72233 8.10659 9.70201 8.22889 9.6555C8.35119 9.60899 8.46237 9.53733 8.55523 9.44515C8.7361 9.24243 8.82955 8.97645 8.81523 8.70515C8.82351 8.44529 8.73025 8.19242 8.55523 8.00015C8.46368 7.90527 8.35308 7.83083 8.23072 7.78171C8.10835 7.73259 7.97698 7.70991 7.84523 7.71515Z"
        fill={color}
      />
      <path
        d="M9.88477 10.3451V7.1001H11.9998V7.7051H10.5648V8.4351H11.8448V9.0451H10.5648V10.3451H9.88477Z"
        fill={color}
      />
    </svg>
  );
}
