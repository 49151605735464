type Props = {
  className?: string;
  color?: string;
};
export default function DownIcon(props: Props) {
  const { className, color = 'black' } = props;
  return (
    <span className={className}>
      <svg
        width="10"
        height="12"
        viewBox="0 0 10 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.5 6.75L8.96975 6.21975L5.375 9.8145V0.75H4.625V9.8145L1.03025 6.21975L0.5 6.75L5 11.25L9.5 6.75Z"
          fill={color}
        />
      </svg>
    </span>
  );
}
