import { TEXT_LINK_COLOR } from 'constants/common';

type Props = {
  className?: string;
  color?: string;
};

export default function ExternalLink(props: Props) {
  const { className = '', color = TEXT_LINK_COLOR } = props;

  return (
    <span className={className}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.5 13.5H2.5V5.5H6.5V4.5H2.5C2.23478 4.5 1.98043 4.60536 1.79289 4.79289C1.60536 4.98043 1.5 5.23478 1.5 5.5V13.5C1.5 13.7652 1.60536 14.0196 1.79289 14.2071C1.98043 14.3946 2.23478 14.5 2.5 14.5H10.5C10.7652 14.5 11.0196 14.3946 11.2071 14.2071C11.3946 14.0196 11.5 13.7652 11.5 13.5V9.5H10.5V13.5Z"
          fill={color}
        />
        <path
          d="M13.5 1.5H8.5V2.5H12.793L6.5 8.793L7.2075 9.5L13.5 3.207V7.5H14.5V2.5V1.5H13.5Z"
          fill={color}
        />
      </svg>
    </span>
  );
}
