type Props = {
  className?: string;
  color?: string;
};
export default function LeftArrow(props: Props) {
  const { className, color = '#333333' } = props;
  return (
    <span className={className}>
      <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.5 13.5L7.207 12.793L1.414 7L7.207 1.207L6.5 0.5L0 7L6.5 13.5Z"
          fill={color}
        />
      </svg>
    </span>
  );
}
