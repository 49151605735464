type Props = {
  color?: string;
};
export default function ChevronDown(props: Props) {
  const { color = 'white' } = props;
  return (
    <svg width="16" height="16" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.6035 5.10348L13.8965 4.39648L8.10352 10.1895L2.31052 4.39648L1.60352 5.10348L8.10352 11.6035L14.6035 5.10348Z"
        fill={color}
      />
    </svg>
  );
}
