import { Route, Switch } from 'react-router-dom';

import loadable from '@loadable/component';
import styles from './app.module.scss';
import * as URL from 'constants/url';

const TopView = loadable(() => import('view/TopView'));
const StationSearch = loadable(() => import('view/StationSearch'));
const ReservationStep1 = loadable(() => import('view/ReservationStep1'));
const ReservationStep2 = loadable(() => import('view/ReservationStep2'));
const ReservationStep3 = loadable(() => import('view/ReservationStep3'));
const ReservationReceipt = loadable(() => import('view/ReservationReceipt'));
const ReservationList = loadable(() => import('view/ReservationList'));
const ReservationStepInfo = loadable(() => import('view/ReservationInfo'));
const ReservationChangeStep1 = loadable(() => import('view/ReservationChangeStep1'));
const ReservationChangeStep2 = loadable(() => import('view/ReservationChangeStep2'));
const ReservationChangeStep3 = loadable(() => import('view/ReservationChangeStep3'));
const MembershipStep1 = loadable(() => import('view/MembershipStep1'));
const MembershipStep2 = loadable(() => import('view/MembershipStep2'));
const MembershipStep3 = loadable(() => import('view/MembershipStep3'));
const Terms = loadable(() => import('view/Terms'));
const ImportantMatters = loadable(() => import('view/ImportantMatters'));
const MembershipInformation = loadable(() => import('view/MembershipInformation'));
const CancelMembershipStep1 = loadable(() => import('view/CancelMembershipStep1'));
const MembershipChangeStep1 = loadable(() => import('view/MembershipChangeStep1'));
const MembershipChangeStep2 = loadable(() => import('view/MembershipChangeStep2'));
const MembershipChangeStep3 = loadable(() => import('view/MembershipChangeStep3'));

const LoginView = loadable(() => import('view/Login/LoginView'));
const LogoutView = loadable(() => import('view/Login/LogoutView'));
const LoginReceiver = loadable(() => import('view/Login/LoginReceiver'));
const CancelMembershipStep2 = loadable(() => import('view/CancelMembershipStep2'));

const MembershipStatusStoppingUse = loadable(() => import('view/MembershipStatusStoppingUse'));
const MembershipStatusUnderReview = loadable(() => import('view/MembershipStatusUnderReview'));
function App() {
  let pageList = [
    { url: URL.TOP, view: TopView },
    { url: URL.STATIONS, view: StationSearch },
    { url: URL.RESERVATION_STEP1, view: ReservationStep1 },
    { url: URL.RESERVATION_STEP2, view: ReservationStep2 },
    { url: URL.RESERVATION_STEP3, view: ReservationStep3 },
    { url: `${URL.RESERVATION_VIEW}/:id`, view: ReservationStepInfo },
    { url: URL.RESERVATIONS, view: ReservationList },
    { url: `${URL.RESERVATION_RECEIPT}/:id`, view: ReservationReceipt },
    { url: URL.RESERVATION_CHANGE_STEP1, view: ReservationChangeStep1 },
    { url: URL.RESERVATION_CHANGE_STEP2, view: ReservationChangeStep2 },
    { url: URL.RESERVATION_CHANGE_STEP3, view: ReservationChangeStep3 },
    { url: URL.LOGIN, view: LoginView },
    { url: URL.LOGOUT, view: LogoutView },
    { url: `${URL.LOGIN}/success`, view: LoginReceiver },
    { url: URL.MEMBER_STEP1, view: MembershipStep1 },
    { url: URL.MEMBER_STEP2, view: MembershipStep2 },
    { url: URL.MEMBER_STEP3, view: MembershipStep3 },
    { url: URL.TERMS_ORIX, view: Terms },
    { url: URL.IMPORTANT_MATTERS, view: ImportantMatters },
    { url: URL.MEMBER_INFO, view: MembershipInformation },
    { url: URL.MEMBER_WITHDRAWAL_STEP1, view: CancelMembershipStep1 },
    { url: URL.MEMBER_WITHDRAWAL_STEP2, view: CancelMembershipStep2 },
    { url: URL.MEMBER_STATUS_STOP, view: MembershipStatusStoppingUse },
    { url: URL.MEMBER_STATUS_REVIEW, view: MembershipStatusUnderReview },
    { url: URL.MEMBER_CHANGE_STEP1, view: MembershipChangeStep1 },
    { url: URL.MEMBER_CHANGE_STEP2, view: MembershipChangeStep2 },
    { url: URL.MEMBER_CHANGE_STEP3, view: MembershipChangeStep3 },
  ];

  return (
    <div className={styles.container}>
      <Switch>
        {pageList.map((page, index) => (
          <Route key={index} exact path={page.url} component={page.view} />
        ))}
      </Switch>
    </div>
  );
}

export default App;
