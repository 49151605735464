type Props = {
  className?: string;
  color?: string;
};
export default function Calendar(props: Props) {
  const { color = '#333333' } = props;
  return (
    <span className={props.className}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 2.5H13.5C13.7652 2.5 14.0196 2.60536 14.2071 2.79289C14.3946 2.98043 14.5 3.23478 14.5 3.5V7H1.5V3.5C1.5 3.23478 1.60536 2.98043 1.79289 2.79289C1.98043 2.60536 2.23478 2.5 2.5 2.5H4V1.5H5V2.5H11V1.5H12V2.5ZM2.5 6H13.5V3.5H12V5H11V3.5H5V5H4V3.5H2.5V6ZM2.5 13.5H13.5V8H14.5V13.5C14.5 13.7652 14.3946 14.0196 14.2071 14.2071C14.0196 14.3946 13.7652 14.5 13.5 14.5H2.5C2.23478 14.5 1.98043 14.3946 1.79289 14.2071C1.60536 14.0196 1.5 13.7652 1.5 13.5V8H2.5V13.5ZM6 9V10H4V9H6ZM6 12V11H4V12H6ZM7 9H9V10H7V9ZM9 11H7V12H9V11ZM12 9V10H10V9H12ZM12 12V11H10V12H12Z"
          fill={color}
        />
      </svg>
    </span>
  );
}
