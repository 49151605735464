import { CheckResultResponse } from 'CancelMembershipStep1-Types';
import Request, { HttpMethod } from 'utils/fetch';

export const checkReservation = (): Promise<CheckResultResponse> =>
  Request(`/member/checkReservation`, {
    method: HttpMethod.get,
  }).then((data: CheckResultResponse) => {
    return data;
  });
export const cancelMembershipStep1 = (): Promise<any> =>
  Request(`/member/cancel`, {
    method: HttpMethod.post,
  }).then((data: any) => {
    return data;
  });
