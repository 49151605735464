import { combineReducers } from 'redux';
import { ReservableDateResponse, ErrorResponse } from 'Top-Info-Types';
import { createReducer } from 'typesafe-actions';
import { reservableDateAsync } from './actions';

export const reservableDateInfo = createReducer<ReservableDateResponse>(
  {} as ReservableDateResponse,
).handleAction(reservableDateAsync.success, (_state, action) => action.payload);

export const reservableDateError = createReducer<ErrorResponse>({} as ErrorResponse).handleAction(
  reservableDateAsync.failure,
  (_state, action) => action.payload,
);

const topReducer = combineReducers({
  reservableDateInfo,
  reservableDateError,
});

export default topReducer;
