import Request, { HttpMethod } from 'utils/fetch';
import {
  ReceiptInfoReq,
  ReceiptInfoResponse,
  GetReservationReceiptRequest,
  GetReservationReceiptResponse,
} from 'Receipt-Types';

export const fetchReceiptPdfData = (
  req: GetReservationReceiptRequest,
): Promise<GetReservationReceiptResponse> =>
  Request(`/reservation/receipt/publish/${req.reservationId}`, {
    method: HttpMethod.get,
    body: req,
  }).then(response => {
    return response;
  });
export const fetchReceiptInfoData = (req: ReceiptInfoReq): Promise<ReceiptInfoResponse> =>
  Request(`/reservation/receipt/getReceiptInfo`, {
    method: HttpMethod.get,
    body: req,
  }).then(response => {
    return response;
  });
