import { CheckResultResponse, ErrorResponse } from 'CancelMembershipStep1-Types';
import { createAsyncAction, AppThunk } from 'typesafe-actions';
import * as cancelMembershipStep1Api from './api';

export const checkReservationAsync = createAsyncAction(
  'CHECK_RESERVATION_REQUEST',
  'CHECK_RESERVATION_SUCCESS',
  'CHECK_RESERVATION_FAILURE',
)<string, CheckResultResponse, ErrorResponse>();

export const checkReservation = (): AppThunk => async dispatch => {
  dispatch(checkReservationAsync.request(''));
  try {
    const response: CheckResultResponse = await cancelMembershipStep1Api.checkReservation();
    dispatch(checkReservationAsync.failure({ traceId: '', message: '', step: 0 } as ErrorResponse));
    dispatch(checkReservationAsync.success(response));
  } catch (error) {
    dispatch(checkReservationAsync.failure(error as ErrorResponse));
  }
};

export const cancelMembershipStep1Async = createAsyncAction(
  'CANCEL_MEMBERSHIP_STEP1_REQUEST',
  'CANCEL_MEMBERSHIP_STEP1_SUCCESS',
  'CANCEL_MEMBERSHIP_STEP1_FAILURE',
)<string, {}, ErrorResponse>();

export const cancelMembershipStep1 = (): AppThunk => async dispatch => {
  dispatch(cancelMembershipStep1Async.request(''));
  try {
    const response: {} = await cancelMembershipStep1Api.cancelMembershipStep1();
    dispatch(
      cancelMembershipStep1Async.failure({ traceId: '', message: '', step: 0 } as ErrorResponse),
    );
    dispatch(cancelMembershipStep1Async.success(response));
  } catch (error) {
    dispatch(cancelMembershipStep1Async.failure(error as ErrorResponse));
  }
};
