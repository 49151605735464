import { Themes } from 'components/Checkbox';

type Props = {
  className?: string;
  isChecked?: boolean;
  theme?: Themes;
};

export default function CheckMark(props: Props) {
  const { className, isChecked, theme = 'LIGHT' } = props;
  const borderColor = theme === 'LIGHT' ? '#606060' : 'white';
  const fillColor = theme === 'LIGHT' ? 'white' : '#333333';

  return (
    <span className={className}>
      {isChecked ? (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 4C1 2.34315 2.34315 1 4 1H20C21.6569 1 23 2.34315 23 4V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V4Z"
            fill="#BF0000"
            stroke="#BF0000"
            stroke-width="2"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.25 8.78025L16.7198 8.25L10.125 14.8444L7.28025 12L6.75 12.5303L10.125 15.9053L17.25 8.78025Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 4C1 2.34315 2.34315 1 4 1H20C21.6569 1 23 2.34315 23 4V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V4Z"
            fill={fillColor}
            stroke={borderColor}
            stroke-width="2"
          />
        </svg>
      )}
    </span>
  );
}
