type Props = {
  className?: string;
};

export default function MasterCardIcon(props: Props) {
  return (
    <span className={props.className}>
      <svg
        width="28"
        height="20"
        viewBox="0 0 28 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.25 2C0.25 1.0335 1.0335 0.25 2 0.25H26C26.9665 0.25 27.75 1.0335 27.75 2V18C27.75 18.9665 26.9665 19.75 26 19.75H2C1.0335 19.75 0.25 18.9665 0.25 18V2Z"
          fill="white"
          stroke="#D1D1D1"
          stroke-width="0.5"
        />
        <path d="M16.1777 6.06885H11.8027V13.9308H16.1777V6.06885Z" fill="#FF5F00" />
        <path
          d="M12.0805 10C12.0798 9.24283 12.2514 8.49543 12.5823 7.81438C12.9131 7.13333 13.3946 6.53647 13.9902 6.069C13.2526 5.48923 12.3668 5.12868 11.434 5.02856C10.5011 4.92844 9.55897 5.09278 8.71513 5.50282C7.87129 5.91285 7.15984 6.55203 6.66209 7.34729C6.16435 8.14256 5.90039 9.06181 5.90039 10C5.90039 10.9382 6.16435 11.8574 6.66209 12.6527C7.15984 13.448 7.87129 14.0871 8.71513 14.4972C9.55897 14.9072 10.5011 15.0716 11.434 14.9714C12.3668 14.8713 13.2526 14.5108 13.9902 13.931C13.3946 13.4635 12.9132 12.8667 12.5823 12.1856C12.2514 11.5046 12.0799 10.7572 12.0805 10Z"
          fill="#EB001B"
        />
        <path
          d="M22.0798 10C22.0799 10.9382 21.816 11.8574 21.3182 12.6527C20.8205 13.448 20.1091 14.0871 19.2653 14.4972C18.4214 14.9072 17.4793 15.0716 16.5465 14.9714C15.6136 14.8713 14.7278 14.5108 13.9902 13.931C14.5853 13.4631 15.0664 12.8661 15.3972 12.1852C15.728 11.5042 15.8999 10.757 15.8999 10C15.8999 9.24295 15.728 8.49579 15.3972 7.81484C15.0664 7.13389 14.5853 6.53694 13.9902 6.069C14.7278 5.48923 15.6136 5.12868 16.5465 5.02856C17.4793 4.92844 18.4214 5.09279 19.2653 5.50283C20.1091 5.91287 20.8205 6.55205 21.3182 7.34731C21.816 8.14257 22.0799 9.06183 22.0798 10Z"
          fill="#F59600"
        />
      </svg>
    </span>
  );
}
