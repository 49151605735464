import {
  ReservationChangeStep3InitialRequest,
  ReservationChangeStep3InitialResponse,
} from 'ReservationChangeStep3-Types';
import { fetchError } from 'ReservationStep1-Types';
import { fetchReservationChangeStep3InitialData } from 'store/reservationChangeStep3/api';
import { AppThunk, createAction, createAsyncAction } from 'typesafe-actions';

export const fetchReservationChangeStep3InitialAsync = createAsyncAction(
  'FETCH_RESERVATION_CHANGE_STEP3_INITIAL_REQUEST',
  'FETCH_RESERVATION_CHANGE_STEP3_INITIAL_SUCCESS',
  'FETCH_RESERVATION_CHANGE_STEP3_INITIAL_FAILURE',
)<undefined, ReservationChangeStep3InitialResponse, fetchError>();

export const fetchReservationChangeStep3Initial = (
  req: ReservationChangeStep3InitialRequest,
): AppThunk => async dispatch => {
  dispatch(fetchReservationChangeStep3InitialAsync.request());
  dispatch(fetchReservationChangeStep3InitialAsync.failure({} as fetchError));
  try {
    const response = await fetchReservationChangeStep3InitialData(req);
    dispatch(fetchReservationChangeStep3InitialAsync.success(response));
  } catch (error) {
    dispatch(fetchReservationChangeStep3InitialAsync.failure(error as fetchError));
  }
};

export const updateFromStepIsTwo = createAction('UPDATE_FROM_STEP_IS_TWO')<boolean>();
