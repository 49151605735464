import Request, { HttpMethod } from 'utils/fetch';
import {
  ReservationChangeStep2InitialRequest,
  ReservationChangeStep2InitialResponse,
} from 'ReservationChangeStep2-Types';

export const fetchReservationChangeStep2InitialData = (
  req: ReservationChangeStep2InitialRequest,
): Promise<ReservationChangeStep2InitialResponse> =>
  Request(`/reservation/change/step2`, {
    method: HttpMethod.get,
    body: req,
  }).then(response => {
    return response;
  });
