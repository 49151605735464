import { ReservationChangeStepError } from 'ReservationChangeStep1-Types';
import {
  ReservationChangeStep2InitialRequest,
  ReservationChangeStep2InitialResponse,
} from 'ReservationChangeStep2-Types';
import { fetchError } from 'ReservationStep1-Types';
import { fetchReservationChangeStep2InitialData } from 'store/reservationChangeStep2/api';
import { AppThunk, createAsyncAction } from 'typesafe-actions';

export const fetchReservationChangeStep2InitialAsync = createAsyncAction(
  'FETCH_RESERVATION_CHANGE_STEP2_INITIAL_REQUEST',
  'FETCH_RESERVATION_CHANGE_STEP2_INITIAL_SUCCESS',
  'FETCH_RESERVATION_CHANGE_STEP2_INITIAL_FAILURE',
)<undefined, ReservationChangeStep2InitialResponse, ReservationChangeStepError>();

export const fetchReservationChangeStep2Initial = (
  req: ReservationChangeStep2InitialRequest,
): AppThunk => async dispatch => {
  dispatch(fetchReservationChangeStep2InitialAsync.request());
  dispatch(fetchReservationChangeStep2InitialAsync.failure({} as ReservationChangeStepError));
  try {
    const response = await fetchReservationChangeStep2InitialData(req);
    dispatch(fetchReservationChangeStep2InitialAsync.success(response));
  } catch (error) {
    dispatch(fetchReservationChangeStep2InitialAsync.failure(error as fetchError));
  }
};
