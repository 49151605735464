import { ErrorResponse, ReservableDateResponse } from 'Top-Info-Types';
import { createAsyncAction, AppThunk } from 'typesafe-actions';
import * as topApi from './api';

export const reservableDateAsync = createAsyncAction(
  'RESERVABLE_DATE_REQUEST',
  'RESERVABLE_DATE_SUCCESS',
  'RESERVABLE_DATE_FAILURE',
)<undefined, ReservableDateResponse, ErrorResponse>();

export const fetchReservableDate = (): AppThunk => async dispatch => {
  dispatch(reservableDateAsync.request());
  try {
    const response: ReservableDateResponse = await topApi.getReservableDate();
    dispatch(reservableDateAsync.success(response));
    dispatch(reservableDateAsync.failure({} as ErrorResponse));
  } catch (error) {
    dispatch(reservableDateAsync.failure(error as ErrorResponse));
  }
};
