import Request, { HttpMethod } from 'utils/fetch';

export const updateTerms = (partnerId: number): Promise<{}> =>
  Request(`/membership/update`, {
    method: HttpMethod.put,
    body: {
      partnerId: partnerId,
    },
  }).then((data: {}) => {
    return data;
  });
