import { createAsyncAction, AppThunk, createAction } from 'typesafe-actions';
import { ErrorResponse, ReceiptInfoReq, ReceiptInfoResponse } from 'Receipt-Types';
import { fetchReceiptInfoData } from './api';

export const fetchReceiptInfoAsync = createAsyncAction(
  'FETCH_RECEIPT_INFO_REQUEST',
  'FETCH_RECEIPT_INFO_SUCCESS',
  'FETCH_RECEIPT_INFO_FAILURE',
)<undefined, ReceiptInfoResponse, ErrorResponse>();

export const fetchReceiptInfo = (req: ReceiptInfoReq): AppThunk => async dispatch => {
  dispatch(fetchReceiptInfoAsync.request());
  try {
    const response = await fetchReceiptInfoData(req);
    dispatch(fetchReceiptInfoAsync.success(response));
    dispatch(fetchReceiptInfoAsync.failure({} as ErrorResponse));
  } catch (error) {
    dispatch(fetchReceiptInfoAsync.failure(error as ErrorResponse));
  }
};

export const setReceiptInfo = createAction('RECEIPT_INFO')<ReceiptInfoResponse>();
