import { createAsyncAction, AppThunk } from 'typesafe-actions';
import { ReCancelReq, ErrorResponse } from 'ReservationInfo-Types';
import { fetchCancelReservationData } from './api';

export const fetchCancelReservationAsync = createAsyncAction(
  'FETCH_CANCEL_RE_REQUEST',
  'FETCH_CANCEL_RE_SUCCESS',
  'FETCH_CANCEL_RE_FAILURE',
)<undefined, ErrorResponse, ErrorResponse>();

export const fetchCancelRe = (req: ReCancelReq): AppThunk => async dispatch => {
  dispatch(fetchCancelReservationAsync.request());
  try {
    const response = await fetchCancelReservationData(req);
    dispatch(fetchCancelReservationAsync.success(response));
    dispatch(fetchCancelReservationAsync.failure({} as ErrorResponse));
  } catch (error) {
    dispatch(fetchCancelReservationAsync.failure(error as ErrorResponse));
  }
};
