import { createStore, applyMiddleware, compose } from 'redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { RootState, RootAction } from 'typesafe-actions';
import rootReducer from './rootReducer';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  storage,
};

const configureStore = (preloadedState = {}) => {
  const middlewares = [thunk as ThunkMiddleware<RootState, RootAction>];
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const store = createStore(
    persistedReducer,
    preloadedState,
    compose(applyMiddleware(...middlewares)),
  );
  return store;
};

export const store = configureStore({});
export const persistor = persistStore(store);
