type Props = {
  className?: string;
  color?: string;
};

export default function BottomIcon(props: Props) {
  const { className = '', color = '#1D54A7' } = props;
  return (
    <span className={className}>
      <svg
        width="13"
        height="14"
        viewBox="0 0 13 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.4286 8L11.7216 7.293L6.92859 12.086V0H5.92859V12.086L1.13559 7.293L0.428589 8L6.42859 14L12.4286 8Z"
          fill={color}
        />
      </svg>
    </span>
  );
}
