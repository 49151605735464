type Props = {
  className?: string;
  color?: string;
};
export default function CircleMoneyIcon(props: Props) {
  const { className, color = 'black' } = props;
  return (
    <span className={className}>
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.04102 1C6.65655 1 5.30317 1.41054 4.15203 2.17971C3.00088 2.94888 2.10368 4.04213 1.57386 5.32122C1.04405 6.6003 0.905425 8.00776 1.17552 9.36563C1.44562 10.7235 2.1123 11.9708 3.09127 12.9497C4.07024 13.9287 5.31752 14.5954 6.67539 14.8655C8.03325 15.1356 9.44072 14.997 10.7198 14.4672C11.9989 13.9373 13.0921 13.0401 13.8613 11.889C14.6305 10.7378 15.041 9.38447 15.041 8C15.041 6.14348 14.3035 4.36301 12.9908 3.05025C11.678 1.7375 9.89753 1 8.04102 1ZM8.04102 14C6.85433 14 5.69429 13.6481 4.7076 12.9888C3.7209 12.3295 2.95187 11.3925 2.49774 10.2961C2.04362 9.19974 1.9248 7.99334 2.15631 6.82946C2.38782 5.66557 2.95926 4.59647 3.79838 3.75736C4.63749 2.91824 5.70659 2.3468 6.87048 2.11529C8.03436 1.88378 9.24076 2.0026 10.3371 2.45672C11.4335 2.91085 12.3705 3.67988 13.0298 4.66658C13.6891 5.65327 14.041 6.81331 14.041 8C14.041 9.5913 13.4089 11.1174 12.2837 12.2426C11.1584 13.3679 9.63232 14 8.04102 14Z"
          fill={color}
        />
        <path
          d="M5.44148 10.64V9.5H7.23648V8.835H5.44148V7.685H6.54148L4.89648 4.5H6.68148L8.04148 7.685L9.43148 4.5H11.0915L9.45148 7.685H10.5415V8.835H8.75648V9.5H10.5415V10.65H8.75648V12H7.25648V10.64H5.44148Z"
          fill={color}
        />
      </svg>
    </span>
  );
}
