import Request, { HttpMethod } from 'utils/fetch';
import {
  MembershipChangeStep1Response,
  MembershipInfo,
  MembershipInformationResponse,
} from 'MembershipInformation-Types';

export const getMembershipInformation = (): Promise<MembershipInformationResponse> =>
  Request(`/membership/information`, {
    method: HttpMethod.get,
  }).then((data: MembershipInformationResponse) => {
    return data;
  });

export const getMembershipChangeStep1 = (): Promise<MembershipChangeStep1Response> =>
  Request(`/membership/change/step1`, {
    method: HttpMethod.get,
  }).then((data: MembershipChangeStep1Response) => {
    return data;
  });

export const postMembershipChangeStep2 = (req: Partial<MembershipInfo>): Promise<{}> =>
  Request(`/membership/change/step2`, {
    method: HttpMethod.post,
    body: req,
  }).then(data => {
    return data;
  });

export const postMembershipChangeStep3 = (req: Partial<MembershipInfo>): Promise<{}> =>
  Request(`/membership/change/step3`, {
    method: HttpMethod.post,
    body: req,
  }).then(data => {
    return data;
  });
