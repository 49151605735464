type Props = {
  className?: string;
  color?: string;
};

export default function Upload(props: Props) {
  const { className, color = '#333333' } = props;
  return (
    <span className={className}>
      <svg
        width="15"
        height="13"
        viewBox="0 0 15 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.5 8.50006H9.5V4.50006H11.5L7.874 0.662557C7.676 0.446557 7.335 0.446057 7.137 0.661557L3.5 4.50006H5.5V8.50006ZM13.5 9.50006V12.0001H1.5V9.50006H0.5V12.0001C0.5 12.5501 0.95 13.0001 1.5 13.0001H13.5C14.05 13.0001 14.5 12.5501 14.5 12.0001V9.50006H13.5ZM9.5 10.5001H5.5V9.50006H9.5V10.5001Z"
          fill={color}
        />
      </svg>
    </span>
  );
}
