type Props = {
  className?: string;
  color?: string;
};

export default function CouponUseIcon(props: Props) {
  const { className, color = '#BF0000' } = props;

  return (
    <span className={className}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 7V3C15 2.73478 14.8946 2.48043 14.7071 2.29289C14.5196 2.10536 14.2652 2 14 2H2C1.73478 2 1.48043 2.10536 1.29289 2.29289C1.10536 2.48043 1 2.73478 1 3V7C1.26522 7 1.51957 7.10536 1.70711 7.29289C1.89464 7.48043 2 7.73478 2 8C2 8.26522 1.89464 8.51957 1.70711 8.70711C1.51957 8.89464 1.26522 9 1 9V13C1 13.2652 1.10536 13.5196 1.29289 13.7071C1.48043 13.8946 1.73478 14 2 14H14C14.2652 14 14.5196 13.8946 14.7071 13.7071C14.8946 13.5196 15 13.2652 15 13V9C14.7348 9 14.4804 8.89464 14.2929 8.70711C14.1054 8.51957 14 8.26522 14 8C14 7.73478 14.1054 7.48043 14.2929 7.29289C14.4804 7.10536 14.7348 7 15 7ZM14 6.26845C13.696 6.44388 13.4435 6.69626 13.268 7.00022C13.0924 7.30418 13 7.649 13 8C13 8.351 13.0924 8.69582 13.268 8.99978C13.4435 9.30374 13.696 9.55612 14 9.73155V13H2V9.73155C2.30402 9.55612 2.55649 9.30374 2.73203 8.99978C2.90757 8.69582 2.99998 8.351 2.99998 8C2.99998 7.649 2.90757 7.30418 2.73203 7.00022C2.55649 6.69626 2.30402 6.44388 2 6.26845V3H14V6.26845Z"
          fill={color}
        />
        <path d="M6 7H5V9H6V7Z" fill={color} />
        <path d="M6 4H5V6H6V4Z" fill={color} />
        <path d="M6 10H5V12H6V10Z" fill={color} />
      </svg>
    </span>
  );
}
