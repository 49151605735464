type Props = {
  className?: string;
  color?: string;
};
export default function ActiveCouponIcon(props: Props) {
  const { color = '#BF0000' } = props;
  return (
    <span className={props.className}>
      <svg
        width="22"
        height="18"
        viewBox="0 0 22 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.5 6.75V1.5C21.5 1.10218 21.342 0.720644 21.0607 0.43934C20.7794 0.158035 20.3978 0 20 0H2C1.60218 0 1.22064 0.158035 0.93934 0.43934C0.658035 0.720644 0.5 1.10218 0.5 1.5V6.75C1.09674 6.75 1.66903 6.98705 2.09099 7.40901C2.51295 7.83097 2.75 8.40326 2.75 9C2.75 9.59674 2.51295 10.169 2.09099 10.591C1.66903 11.0129 1.09674 11.25 0.5 11.25V16.5C0.5 16.8978 0.658035 17.2794 0.93934 17.5607C1.22064 17.842 1.60218 18 2 18H20C20.3978 18 20.7794 17.842 21.0607 17.5607C21.342 17.2794 21.5 16.8978 21.5 16.5V11.25C20.9033 11.25 20.331 11.0129 19.909 10.591C19.4871 10.169 19.25 9.59674 19.25 9C19.25 8.40326 19.4871 7.83097 19.909 7.40901C20.331 6.98705 20.9033 6.75 21.5 6.75ZM8.75 10.5H7.25V7.5H8.75V10.5ZM8.75 6H7.25V3H8.75V6ZM8.75 15H7.25V12H8.75V15Z"
          fill={color}
        />
      </svg>
    </span>
  );
}
