import Request, { HttpMethod, RequestWithSignal } from 'utils/fetch';
import {
  CancelBookRequest,
  CancelBookResponse,
  ReInfoRequest,
  ReInfoResponse,
  ReListRequest,
  ReListResponse,
} from 'ReservationList-Types';

export const fetchReListData = (req: ReListRequest): Promise<ReListResponse> =>
  RequestWithSignal(`/reservation/get`, {
    method: HttpMethod.get,
    body: req,
  }).then(response => {
    return response;
  });
export const fetchCancelBookData = (req: CancelBookRequest): Promise<CancelBookResponse> =>
  Request(`/reservation/cancel/${req.reservationId}`, {
    method: HttpMethod.put,
    body: req,
  }).then(response => {
    return response;
  });
export const fetchReInfoData = (req: ReInfoRequest, id: string): Promise<ReInfoResponse> =>
  Request(`/reservation/get/${id}`, {
    method: HttpMethod.get,
    body: req,
  }).then(response => {
    return response;
  });
