type Props = {
  className?: string;
  color?: string;
};
export default function CouponIcon(props: Props) {
  const { className, color = '#BF0000' } = props;
  return (
    <span className={className}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M11.3496 35.8983L7.2041 32.4189H36.2707L11.3496 35.8983Z" fill={color} />
        <path
          d="M16.0692 29.8115V22.1077H19.4083L25.1883 29.8115H31.0998L24.1147 20.517C26.2926 18.9526 27.7212 16.4153 27.7212 13.5363C27.7212 8.80798 23.8781 4.96924 19.1498 4.96924H11.3496V29.8115H16.0692ZM16.0692 9.6844H19.1498C21.2707 9.6844 23.0017 11.411 23.0017 13.5319C23.0017 15.6616 21.2663 17.3838 19.1498 17.3838H16.0692V9.6844Z"
          fill={color}
        />
      </svg>
    </span>
  );
}
