type Props = {
  className?: string;
};

export default function WaveLine(props: Props) {
  return (
    <span className={props.className}>
      <svg
        width="13"
        height="32"
        viewBox="0 0 13 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.864 18.06C10.136 18.06 11.24 17.328 11.996 15.96L11.144 15.504C10.652 16.452 9.824 17.088 8.876 17.088C8.012 17.088 7.484 16.716 6.836 16.104C5.996 15.264 5.228 14.82 4.136 14.82C2.864 14.82 1.76 15.552 1.004 16.92L1.856 17.376C2.348 16.428 3.176 15.792 4.124 15.792C5 15.792 5.516 16.164 6.164 16.776C7.004 17.604 7.772 18.06 8.864 18.06Z"
          fill="white"
        />
      </svg>
    </span>
  );
}
