type Props = {
  className?: string;
  color?: string;
};

export default function Close(props: Props) {
  const { color = '#BF0000', className } = props;
  return (
    <span className={className}>
      <svg
        width="13"
        height="14"
        viewBox="0 0 13 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.2068 2.28513C12.5978 2.67613 12.5978 3.31012 12.2068 3.70112L11.4858 4.42212L10.7783 3.71513L11.4993 2.99412L9.5058 1.00063L8.7848 1.72162L8.0778 1.01462L8.7988 0.293625C9.1898 -0.097875 9.8238 -0.097875 10.2148 0.293625L12.2068 2.28513ZM2.31055 8.46286L4.03755 10.1894L1.81705 10.6829L2.31055 8.46286ZM4.87805 9.61586L2.88405 7.62186L7.37005 3.13586L9.36405 5.12936L4.87805 9.61586ZM10.7786 5.12936L10.0711 4.42236L7.37305 1.72336L7.37055 1.72186L1.47355 7.61836L0.500053 11.9999L4.88155 11.0264L10.7786 5.12936ZM12.5 14H0.5V13H12.5V14Z"
          fill={color}
        />
      </svg>
    </span>
  );
}
