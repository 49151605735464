import { Themes } from 'components/Message';

type Props = {
  className?: string;
  theme: Themes;
};

export default function InfoOutline(props: Props) {
  const { className, theme } = props;
  const color = theme === 'ERROR' ? '#DF0101' : '#006497';

  return (
    <span className={className}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 1C6.61553 1 5.26215 1.41054 4.11101 2.17971C2.95986 2.94888 2.06265 4.04213 1.53284 5.32122C1.00303 6.6003 0.864404 8.00776 1.1345 9.36563C1.4046 10.7235 2.07128 11.9708 3.05025 12.9497C4.02922 13.9287 5.2765 14.5954 6.63437 14.8655C7.99223 15.1356 9.3997 14.997 10.6788 14.4672C11.9579 13.9373 13.0511 13.0401 13.8203 11.889C14.5895 10.7378 15 9.38447 15 8C15 6.14348 14.2625 4.36301 12.9497 3.05025C11.637 1.7375 9.85651 1 8 1ZM8 14C6.81331 14 5.65327 13.6481 4.66658 12.9888C3.67988 12.3295 2.91085 11.3925 2.45672 10.2961C2.00259 9.19974 1.88377 7.99334 2.11529 6.82946C2.3468 5.66557 2.91824 4.59647 3.75736 3.75736C4.59647 2.91824 5.66557 2.3468 6.82946 2.11529C7.99334 1.88378 9.19974 2.0026 10.2961 2.45672C11.3925 2.91085 12.3295 3.67988 12.9888 4.66658C13.6481 5.65327 14 6.81331 14 8C14 9.5913 13.3679 11.1174 12.2426 12.2426C11.1174 13.3679 9.5913 14 8 14Z"
          fill={color}
        />
        <path
          d="M8 6.07502C7.89385 6.07784 7.78828 6.05839 7.6901 6.01791C7.59193 5.97744 7.50332 5.91683 7.43 5.84002C7.35733 5.76722 7.29998 5.68061 7.26133 5.58529C7.22269 5.48996 7.20354 5.38786 7.205 5.28502C7.20362 5.1807 7.22332 5.07717 7.26291 4.98065C7.3025 4.88413 7.36117 4.79659 7.43541 4.72329C7.50964 4.64999 7.59791 4.59243 7.69493 4.55406C7.79195 4.5157 7.89571 4.49731 8 4.50002C8.10716 4.49754 8.21371 4.51663 8.31335 4.55614C8.41298 4.59565 8.50366 4.65478 8.58 4.73002C8.65222 4.80314 8.70928 4.88982 8.74789 4.98506C8.7865 5.08031 8.80591 5.18224 8.805 5.28502C8.80647 5.38786 8.78731 5.48996 8.74867 5.58529C8.71003 5.68061 8.65267 5.76722 8.58 5.84002C8.50461 5.91686 8.41421 5.97738 8.31443 6.01781C8.21465 6.05824 8.10763 6.07771 8 6.07502ZM7.325 11.5V6.61502H8.655V11.5H7.325Z"
          fill={color}
        />
      </svg>
    </span>
  );
}
