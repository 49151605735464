import { MouseEventHandler } from 'react';

type Props = {
  className?: string;
  color?: string;
  onClick?: MouseEventHandler<HTMLSpanElement>;
};

export default function Close(props: Props) {
  const { color = '#333333', className, ...rest } = props;
  return (
    <span className={className} {...rest}>
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5 1.2265L10.7735 0.5L6 5.2735L1.2265 0.5L0.5 1.2265L5.2735 6L0.5 10.7735L1.2265 11.5L6 6.7265L10.7735 11.5L11.5 10.7735L6.7265 6L11.5 1.2265Z"
          fill={color}
        />
      </svg>
    </span>
  );
}
