import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import * as actions from './actions';

export const isUpdating = createReducer<boolean>(false)
  .handleAction([actions.updateTermsAsync.request], () => true)
  .handleAction([actions.updateTermsAsync.success, actions.updateTermsAsync.failure], () => false);

export const updateTermsError = createReducer<Error>({} as Error).handleAction(
  actions.updateTermsAsync.failure,
  (state, action) => action.payload,
);

const termsReducer = combineReducers({
  isUpdating,
  updateTermsError,
});

export default termsReducer;
export type TermsState = ReturnType<typeof termsReducer>;
