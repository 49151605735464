type Props = {
  className?: string;
};

export default function VisaIcon(props: Props) {
  return (
    <span className={props.className}>
      <svg
        width="28"
        height="20"
        viewBox="0 0 28 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 0.25H26C26.9665 0.25 27.75 1.0335 27.75 2V18C27.75 18.9665 26.9665 19.75 26 19.75H2C1.0335 19.75 0.25 18.9665 0.25 18V2C0.25 1.0335 1.0335 0.25 2 0.25Z"
          fill="white"
          stroke="#D1D1D1"
          stroke-width="0.5"
        />
        <path
          d="M11.3657 6.64239L8.4822 13.5129H6.59547L5.17152 8.03074C5.10032 7.71036 4.99353 7.56796 4.74434 7.42557C4.31715 7.21197 3.60518 6.96278 3 6.85599L3.0356 6.64239H6.06149C6.45307 6.64239 6.80906 6.89159 6.88026 7.35437L7.62783 11.3414L9.47896 6.67799H11.3657V6.64239ZM18.699 11.2346C18.699 9.41909 16.2071 9.3123 16.2071 8.52913C16.2071 8.27993 16.4563 8.03074 16.9547 7.95955C17.2039 7.92395 17.9159 7.88835 18.699 8.27993L19.0194 6.82039C18.5922 6.67799 18.0583 6.5 17.3463 6.5C15.6019 6.5 14.356 7.42557 14.356 8.77832C14.356 9.77508 15.246 10.3091 15.9223 10.6294C16.5987 10.9498 16.8479 11.1634 16.8479 11.4838C16.8479 11.9466 16.3139 12.1602 15.7799 12.1602C14.89 12.1602 14.356 11.911 13.9644 11.733L13.644 13.2282C14.0712 13.4061 14.8188 13.5841 15.6019 13.5841C17.4887 13.5841 18.699 12.6586 18.699 11.2346ZM23.3625 13.4773H25L23.5761 6.6068H22.0453C21.6893 6.6068 21.4045 6.82039 21.2977 7.10518L18.6278 13.4773H20.479L20.835 12.445H23.1133L23.3625 13.4773ZM21.3689 11.0566L22.2945 8.49353L22.8285 11.0566H21.3689ZM13.8932 6.64239L12.4337 13.5129H10.6537L12.1133 6.64239H13.8932Z"
          fill="#1A1F71"
        />
      </svg>
    </span>
  );
}
