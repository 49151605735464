import { ReservationStepError } from 'ReservationStep1-Types';
import { fetchReservationStep3InitialData } from './api';
import { AppThunk, createAsyncAction } from 'typesafe-actions';
import {
  ReservationStep3InitialRequest,
  ReservationStep3InitialResponse,
} from 'ReservationStep3-Types';

export const fetchReservationStep3InitialAsync = createAsyncAction(
  'FETCH_RESERVATION_STEP3_INITIAL_REQUEST',
  'FETCH_RESERVATION_STEP3_INITIAL_SUCCESS',
  'FETCH_RESERVATION_STEP3_INITIAL_FAILURE',
)<undefined, ReservationStep3InitialResponse, ReservationStepError>();

export const fetchReservationStep3Initial = (
  req: ReservationStep3InitialRequest,
): AppThunk => async dispatch => {
  dispatch(fetchReservationStep3InitialAsync.request());
  try {
    const response = await fetchReservationStep3InitialData(req);
    dispatch(fetchReservationStep3InitialAsync.success(response));
    dispatch(fetchReservationStep3InitialAsync.failure({} as ReservationStepError));
  } catch (error) {
    if (error.status) {
      dispatch(fetchReservationStep3InitialAsync.failure(error as ReservationStepError));
    } else {
      dispatch(
        fetchReservationStep3InitialAsync.failure({
          status: 500,
          message: error.message,
        } as ReservationStepError),
      );
    }
  }
};
