import { ErrorResponse } from 'Membership-Types';
import { createAsyncAction, AppThunk } from 'typesafe-actions';
import * as termsApi from './api';

export const updateTermsAsync = createAsyncAction(
  'UPDATE_TERMS_REQUEST',
  'UPDATE_TERMS_SUCCESS',
  'UPDATE_TERMS_FAILURE',
)<string, {}, ErrorResponse>();

export const updateTerms = (partnerId: number): AppThunk => async dispatch => {
  dispatch(updateTermsAsync.request(''));
  dispatch(updateTermsAsync.failure({} as ErrorResponse));
  try {
    const response: {} = await termsApi.updateTerms(partnerId);
    dispatch(updateTermsAsync.success(response));
  } catch (error) {
    dispatch(updateTermsAsync.failure(error as ErrorResponse));
  }
};
