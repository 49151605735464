import { ErrorResponse } from 'MembershipInformation-Types';
import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import * as actions from './actions';

export const withdrawalError = createReducer({} as ErrorResponse).handleAction(
  actions.getMembershipWithdrawalAsync.failure,
  (_, action) => action.payload,
);

const membershipWithdrawalReducer = combineReducers({
  withdrawalError,
});

export default membershipWithdrawalReducer;
export type MembershipWithdrawalState = ReturnType<typeof membershipWithdrawalReducer>;
