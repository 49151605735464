import { combineReducers } from 'redux';
import { ReservationStepError } from 'ReservationStep1-Types';
import { ReservationStep3InitialResponse } from 'ReservationStep3-Types';
import { createReducer } from 'typesafe-actions';
import * as actions from './actions';

const isFetchingReservationStep3 = createReducer<boolean>(false)
  .handleAction([actions.fetchReservationStep3InitialAsync.request], () => true)
  .handleAction(
    [
      actions.fetchReservationStep3InitialAsync.success,
      actions.fetchReservationStep3InitialAsync.failure,
    ],
    () => false,
  );

const initialDataFetching = createReducer<ReservationStep3InitialResponse>(
  {} as ReservationStep3InitialResponse,
).handleAction(
  actions.fetchReservationStep3InitialAsync.success,
  (state, action) => action.payload,
);

const initialDataFetchingError = createReducer<ReservationStepError>(
  {} as ReservationStepError,
).handleAction(
  actions.fetchReservationStep3InitialAsync.failure,
  (_state, action) => action.payload,
);

const reservationStep3Reducer = combineReducers({
  isFetchingReservationStep3,
  initialDataFetching,
  initialDataFetchingError,
});

export default reservationStep3Reducer;
export type ReservationListState = ReturnType<typeof reservationStep3Reducer>;
