type Props = {
  className?: string;
};

export default function User(props: Props) {
  return (
    <span className={props.className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.25014 21.012C5.26514 19.848 5.30939 16.3065 6.30464 15.3105C6.95714 14.658 8.13239 13.8442 9.61364 13.0192C10.2196 12.6818 10.6284 12.096 10.7356 11.412C10.8436 10.7227 10.6299 10.0342 10.1506 9.52275C9.41189 8.7345 8.99414 8.1825 8.99414 6C8.99414 4.61625 9.77939 3 11.9926 3C14.2081 3 14.9941 4.61625 14.9941 6C14.9941 8.1825 14.5756 8.7345 13.8369 9.52275C13.3576 10.0342 13.1446 10.7227 13.2519 11.412C13.3591 12.096 13.7679 12.6818 14.3746 13.0192C15.8559 13.8442 17.0311 14.658 17.6836 15.3105C18.6789 16.3065 18.7231 19.848 18.7464 21L5.25014 21.012ZM18.7441 14.25C17.7526 13.2592 16.1064 12.267 15.1036 11.709C14.6784 11.472 14.5981 10.9035 14.9311 10.548C15.9729 9.43725 16.4941 8.49975 16.4941 6C16.4941 3.75 14.9941 1.5 11.9926 1.5C8.99412 1.5 7.49412 3.75 7.49412 6C7.49412 8.49975 8.01462 9.43725 9.05637 10.548C9.38937 10.9035 9.30987 11.472 8.88387 11.709C7.88112 12.267 6.23487 13.2592 5.24412 14.25C3.98712 15.507 3.78312 18.342 3.75012 20.9932C3.73962 21.8242 4.41087 22.5 5.24112 22.5H18.7464C19.5766 22.5 20.2479 21.8242 20.2374 20.9932C20.2044 18.342 20.0004 15.507 18.7441 14.25Z"
          fill="#686868"
        />
      </svg>
    </span>
  );
}
