import Request, { HttpMethod } from 'utils/fetch';
import { StationSearchCondition, StationInfoResponse } from 'Station-Info-Types';

export const getStationInfo = (req: StationSearchCondition): Promise<StationInfoResponse> =>
  Request(`/station/get`, {
    method: HttpMethod.get,
    body: req,
    isSearch: true,
  }).then((res: StationInfoResponse) => {
    return res;
  });
