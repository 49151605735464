import {
  ErrorResponse,
  MembershipChangeStep1Response,
  MembershipInfo,
  MembershipInformationResponse,
  NewMembershipInfo,
} from 'MembershipInformation-Types';
import { createAsyncAction, AppThunk, createAction } from 'typesafe-actions';
import * as api from './api';

export const getMembershipInformationAsync = createAsyncAction(
  'GET_MEMBERSHIP_INFORMATION_REQUEST',
  'GET_MEMBERSHIP_INFORMATION_SUCCESS',
  'GET_MEMBERSHIP_INFORMATION_FAILURE',
)<string, MembershipInformationResponse, ErrorResponse>();

export const getMembershipInformation = (): AppThunk => async dispatch => {
  dispatch(getMembershipInformationAsync.request(''));
  dispatch(getMembershipInformationAsync.failure({} as ErrorResponse));
  try {
    const response: MembershipInformationResponse = await api.getMembershipInformation();
    dispatch(getMembershipInformationAsync.success(response));
  } catch (error) {
    dispatch(getMembershipInformationAsync.failure(error as ErrorResponse));
  }
};

export const getMembershipChangeStep1Async = createAsyncAction(
  'GET_MEMBERSHIP_CHANGE_STEP1_REQUEST',
  'GET_MEMBERSHIP_CHANGE_STEP1_SUCCESS',
  'GET_MEMBERSHIP_CHANGE_STEP1_FAILURE',
)<string, MembershipChangeStep1Response, ErrorResponse>();

export const getMembershipChangeStep1 = (): AppThunk => async dispatch => {
  dispatch(getMembershipChangeStep1Async.request(''));
  dispatch(getMembershipChangeStep1Async.failure({} as ErrorResponse));
  try {
    dispatch(getMembershipChangeStep1Async.success({} as MembershipChangeStep1Response));
    const response: MembershipChangeStep1Response = await api.getMembershipChangeStep1();
    dispatch(getMembershipChangeStep1Async.success(response));
  } catch (error) {
    dispatch(getMembershipChangeStep1Async.failure(error as ErrorResponse));
  }
};

export const postMembershipChangeStep2Async = createAsyncAction(
  'POST_MEMBERSHIP_CHANGE_STEP2_REQUEST',
  'POST_MEMBERSHIP_CHANGE_STEP2_SUCCESS',
  'POST_MEMBERSHIP_CHANGE_STEP2_FAILURE',
)<string, {}, ErrorResponse>();

export const postMembershipChangeStep2 = (
  membershipInfoToUpdate: Partial<MembershipInfo>,
): AppThunk => async dispatch => {
  dispatch(postMembershipChangeStep2Async.request(''));
  dispatch(postMembershipChangeStep2Async.failure({} as ErrorResponse));
  try {
    const response = await api.postMembershipChangeStep2(membershipInfoToUpdate);
    dispatch(postMembershipChangeStep2Async.success(response));
  } catch (error) {
    dispatch(postMembershipChangeStep2Async.failure(error as ErrorResponse));
  }
};

export const postMembershipChangeStep3Async = createAsyncAction(
  'POST_MEMBERSHIP_CHANGE_STEP3_REQUEST',
  'POST_MEMBERSHIP_CHANGE_STEP3_SUCCESS',
  'POST_MEMBERSHIP_CHANGE_STEP3_FAILURE',
)<string, {}, ErrorResponse>();

export const postMembershipChangeStep3 = (
  membershipInfoToUpdate: Partial<MembershipInfo>,
): AppThunk => async dispatch => {
  dispatch(postMembershipChangeStep3Async.request(''));
  dispatch(postMembershipChangeStep3Async.failure({} as ErrorResponse));
  try {
    const response = await api.postMembershipChangeStep3(membershipInfoToUpdate);
    dispatch(postMembershipChangeStep3Async.success(response));
  } catch (error) {
    dispatch(postMembershipChangeStep3Async.failure(error as ErrorResponse));
  }
};

export const updateMembershipInfo = createAction('UPDATE_MEMBERSHIP_INFO')<NewMembershipInfo>();

export const clearMembershipInfo = createAction('CLEAR_MEMBERSHIP_INFO')<NewMembershipInfo>();
