type Props = {
  className?: string;
};

export default function CheckMark(props: Props) {
  return (
    <span className={props.className}>
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20 10C20 15.2467 15.7467 19.5 10.5 19.5C5.25329 19.5 1 15.2467 1 10C1 4.75329 5.25329 0.5 10.5 0.5C15.7467 0.5 20 4.75329 20 10Z"
          fill="white"
          stroke="#606060"
        />
      </svg>
    </span>
  );
}
