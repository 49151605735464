import { MouseEventHandler } from 'react';

type Props = {
  className?: string;
  color?: string;
  onClick?: MouseEventHandler<HTMLSpanElement>;
};

export default function CircleCloseIcon(props: Props) {
  const { color = 'black', className, ...rest } = props;
  return (
    <span className={className} {...rest}>
      <svg
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_21101_37280)">
          <rect x="8" y="5" width="22" height="22" rx="11" fill="#F7F7F7" />
          <path
            d="M23.8125 11.8232L23.1768 11.1875L19 15.3643L14.8232 11.1875L14.1875 11.8232L18.3643 16L14.1875 20.1768L14.8232 20.8125L19 16.6357L23.1768 20.8125L23.8125 20.1768L19.6357 16L23.8125 11.8232Z"
            fill={color}
          />
        </g>
        <defs>
          <filter
            id="filter0_d_21101_37280"
            x="0"
            y="0"
            width="38"
            height="38"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="3" />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_21101_37280"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_21101_37280"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </span>
  );
}
