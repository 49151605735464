import Request, { HttpMethod } from 'utils/fetch';
import {
  CarAvailabilityRequest,
  CarAvailabilityResponse,
  CheckCouponChangeRequest,
  CheckCouponChangeResponse,
  ReservationChangeStep1InitialRequest,
  ReservationChangeStep1InitialResponse,
} from 'ReservationChangeStep1-Types';

export const fetchReservationChangeStep1InitialData = (
  req: ReservationChangeStep1InitialRequest,
): Promise<ReservationChangeStep1InitialResponse> =>
  Request(`/reservation/change/step1`, {
    method: HttpMethod.get,
    body: req,
  }).then(response => {
    return response;
  });

export const fetchCarAvailabilityData = (
  req: CarAvailabilityRequest,
): Promise<CarAvailabilityResponse> =>
  Request(`/car/availability`, {
    method: HttpMethod.get,
    body: req,
  }).then(response => {
    return response;
  });

export const checkCouponChangeData = (
  req: CheckCouponChangeRequest,
): Promise<CheckCouponChangeResponse> =>
  Request(`/reservation/change/checkCouponChange`, {
    method: HttpMethod.get,
    body: req,
  }).then(response => {
    return response;
  });
