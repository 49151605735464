import { UNAUTHORIZED_ERROR_CODE } from 'constants/common';
import { BUSY } from 'constants/staticUrl';
import { LOGIN } from 'constants/url';

const qs = require('qs');

export enum HttpMethod {
  get = 'GET',
  post = 'POST',
  put = 'PUT',
  patch = 'PATCH',
  delete = 'DELETE',
}

export interface IReqConfig {
  body?: any;
  method?: string;
  headers?: IHeader;
  'Content-Type'?: string;
  isSearch?: boolean;
}

export interface IHeader {
  'Content-Type': string;
  'X-Requested-With': string;
  [propName: string]: any;
}

const replacer = (_, v) => (v !== '' ? v : undefined);
const qsConfig = {
  arrayFormat: 'indices',
  allowDots: true,
  addQueryPrefix: true,
  skipNulls: true,
  filter: replacer,
};

export const baseUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3002'
    : 'https://api.carshare.rakuten.co.jp/carshareapi';
export const searchBaseUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3002'
    : 'https://search.carshare.rakuten.co.jp/carsharesearch';

let controller = new AbortController();

export const CancelRequest = () => controller.abort();

export const RequestWithSignal = async (url: string, config: IReqConfig) => {
  let promise: Response;
  promise = {} as Response;
  let contentType: string;
  try {
    if (config['Content-Type'] !== undefined) {
      contentType = config['Content-Type'];
    } else {
      contentType = 'application/json;charset=UTF-8';
    }
    const reqUrl = baseUrl + url;
    const headers: Headers = new Headers({
      'Content-Type': contentType,
    } as IHeader);
    controller = new AbortController();
    const { signal } = controller;
    if (!config.method || config.method === HttpMethod.get || config.method === HttpMethod.put) {
      promise = await fetch(`${reqUrl}${qs.stringify(config.body, qsConfig)}`, {
        headers,
        credentials: 'include' as RequestCredentials,
        method: config.method || HttpMethod.get,
        signal,
      });
    } else {
      promise = await fetch(reqUrl, {
        body: JSON.stringify(config.body, replacer),
        headers,
        method: config.method,
        credentials: 'include' as RequestCredentials,
        signal,
      });
    }
  } catch (error) {
    let errMsg;
    if (error instanceof Error) {
      errMsg = error.message;
    } else {
      errMsg = String(error);
    }
    const isAborted = errMsg?.includes('The user aborted a request');
    if (!isAborted) {
      window.location.href = BUSY;
    }
  }
  return handleRes(promise);
};
const Request = async (url: string, config: IReqConfig) => {
  let promise: Response;
  promise = {} as Response;
  let contentType: string;
  try {
    if (config['Content-Type'] !== undefined) {
      contentType = config['Content-Type'];
    } else {
      contentType = 'application/json;charset=UTF-8';
    }
    let reqUrl = baseUrl + url;
    if (config.isSearch) {
      reqUrl = searchBaseUrl + url;
    }
    const headers: Headers = new Headers({
      'Content-Type': contentType,
    } as IHeader);
    if (!config.method || config.method === HttpMethod.get || config.method === HttpMethod.put) {
      promise = await fetch(`${reqUrl}${qs.stringify(config.body, qsConfig)}`, {
        headers,
        credentials: 'include' as RequestCredentials,
        method: config.method || HttpMethod.get,
      });
    } else {
      promise = await fetch(reqUrl, {
        body: JSON.stringify(config.body, replacer),
        headers,
        method: config.method,
        credentials: 'include' as RequestCredentials,
      });
    }
  } catch (error) {
    window.location.href = BUSY;
  }
  return handleRes(promise);
};

const handleRes = async (res: Response) => {
  const parsedRes = await parseRes(res);
  if (res.ok) {
    return parsedRes;
  }
  if (res.status === UNAUTHORIZED_ERROR_CODE) {
    window.location.hash = LOGIN;
  }
  const error = { ...parsedRes, status: res.status };
  throw error;
};

const parseRes = async (res: Response) => {
  const contentType = res.headers.get('Content-Type');
  if (contentType) {
    if (contentType.indexOf('json') > -1) {
      return await res.json();
    }
    if (contentType.indexOf('text') > -1) {
      return await res.text();
    }
    if (contentType.indexOf('form') > -1) {
      return await res.formData();
    }
    if (contentType.indexOf('video') > -1) {
      return await res.blob();
    }
  }
  return await res.text();
};

export default Request;
