type Props = {
  className?: string;
  color?: string;
};

export default function Refresh(props: Props) {
  const { className, color = '#BF0000' } = props;
  return (
    <span className={className}>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 7C12 10.0325 9.5325 12.5 6.5 12.5C3.4675 12.5 1 10.0325 1 7C1 3.9675 3.4675 1.5 6.5 1.5C8.046 1.5 9.4875 2.142 10.5275 3.265L9.293 4.5H12.5V1.293L11.235 2.558C10.008 1.2475 8.3145 0.5 6.5 0.5C2.916 0.5 0 3.416 0 7C0 10.584 2.916 13.5 6.5 13.5C10.084 13.5 13 10.584 13 7H12Z"
          fill={color}
        />
      </svg>
    </span>
  );
}
