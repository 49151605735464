type Props = {
  className?: string;
};

export default function ActiveStep(props: Props) {
  return (
    <span className={props.className}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.5 20C16.0228 20 20.5 15.5228 20.5 10C20.5 4.47715 16.0228 0 10.5 0C4.97715 0 0.5 4.47715 0.5 10C0.5 15.5228 4.97715 20 10.5 20Z"
          fill="#BF0000"
        />
        <path
          d="M10.5 20C16.0228 20 20.5 15.5228 20.5 10C20.5 4.47715 16.0228 0 10.5 0C4.97715 0 0.5 4.47715 0.5 10C0.5 15.5228 4.97715 20 10.5 20Z"
          stroke="#BF0000"
        />
        <circle cx="10.5" cy="10" r="4" fill="white" />
      </svg>
    </span>
  );
}
