import {
  ErrorResponse,
  MembershipChangeStep1Response,
  MembershipInformationResponse,
  NewMembershipInfo,
} from 'MembershipInformation-Types';
import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import * as actions from './actions';

export const isFetching = createReducer(true as boolean)
  .handleAction([actions.getMembershipInformationAsync.request], () => true)
  .handleAction(
    [actions.getMembershipInformationAsync.success, actions.getMembershipInformationAsync.failure],
    () => false,
  );

export const membershipInformation = createReducer(
  {} as MembershipInformationResponse,
).handleAction(actions.getMembershipInformationAsync.success, (_, action) => action.payload);

export const error = createReducer({} as ErrorResponse).handleAction(
  actions.getMembershipInformationAsync.failure,
  (_, action) => action.payload,
);

export const changeStep1Information = createReducer(
  {} as MembershipChangeStep1Response,
).handleAction(actions.getMembershipChangeStep1Async.success, (_, action) => action.payload);

export const changeStep1Error = createReducer({} as ErrorResponse).handleAction(
  actions.getMembershipChangeStep1Async.failure,
  (_, action) => action.payload,
);

export const changeStep2Error = createReducer({} as ErrorResponse).handleAction(
  actions.postMembershipChangeStep2Async.failure,
  (_, action) => action.payload,
);

export const changeStep3Error = createReducer({} as ErrorResponse).handleAction(
  actions.postMembershipChangeStep3Async.failure,
  (_, action) => action.payload,
);

export const updatedMembershipInfo = createReducer({} as NewMembershipInfo)
  .handleAction(actions.updateMembershipInfo, (state, action) => {
    return { ...state, ...action.payload };
  })
  .handleAction(actions.clearMembershipInfo, (_, action) => action.payload);

const membershipInformationReducer = combineReducers({
  isFetching,
  membershipInformation,
  error,
  changeStep1Information,
  changeStep1Error,
  changeStep2Error,
  changeStep3Error,
  updatedMembershipInfo,
});

export default membershipInformationReducer;
export type MembershipInformationState = ReturnType<typeof membershipInformationReducer>;
