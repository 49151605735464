type Props = {
  className?: string;
  color?: string;
};
export default function TriangleAlertSingleStyle(props: Props) {
  const { className, color = '#DF0101' } = props;
  return (
    <span className={className}>
      <svg
        width="14"
        height="14"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.63875 2.36002L14.9237 13.47C14.9827 13.577 15.0123 13.6977 15.0098 13.8198C15.0072 13.942 14.9725 14.0613 14.9091 14.1657C14.8457 14.2702 14.7559 14.3561 14.6488 14.4148C14.5416 14.4735 14.4209 14.5029 14.2987 14.5H1.72875C1.60661 14.5029 1.48588 14.4735 1.37873 14.4148C1.27158 14.3561 1.18179 14.2702 1.11841 14.1657C1.05503 14.0613 1.02031 13.942 1.01773 13.8198C1.01516 13.6977 1.04483 13.577 1.10375 13.47L7.38875 2.36002C7.45275 2.25114 7.54409 2.16087 7.65372 2.09815C7.76334 2.03543 7.88745 2.00244 8.01375 2.00244C8.14005 2.00244 8.26415 2.03543 8.37378 2.09815C8.4834 2.16087 8.57474 2.25114 8.63875 2.36002ZM8.01375 3.28502L2.23375 13.5H13.7937L8.01375 3.28502ZM7.64546 12.4324C7.76236 12.4796 7.88765 12.5027 8.01371 12.5C8.1405 12.5025 8.26649 12.4794 8.38418 12.4321C8.50186 12.3849 8.60883 12.3145 8.69872 12.225C8.78914 12.1374 8.86103 12.0325 8.91012 11.9165C8.95922 11.8006 8.98452 11.6759 8.98452 11.55C8.98452 11.4241 8.95922 11.2994 8.91012 11.1835C8.86103 11.0675 8.78914 10.9626 8.69872 10.875C8.51504 10.698 8.26876 10.6009 8.01371 10.605C7.88811 10.6025 7.76328 10.6252 7.64652 10.6715C7.52977 10.7179 7.42342 10.7871 7.33371 10.875C7.24329 10.9626 7.1714 11.0675 7.12231 11.1835C7.07321 11.2994 7.04791 11.4241 7.04791 11.55C7.04791 11.6759 7.07321 11.8006 7.12231 11.9165C7.1714 12.0325 7.24329 12.1374 7.33371 12.225C7.4225 12.3145 7.52856 12.3851 7.64546 12.4324ZM7.28371 5.5L7.38371 9.855H8.66371L8.78372 5.5H7.28371Z"
          fill={color}
        />
      </svg>
    </span>
  );
}
