import { combineReducers } from 'redux';
import reservationStepReducer from './reservationStep1/reducer';
import reservationChangeStep1Reducer from './reservationChangeStep1/reducer';
import reservationChangeStep2Reducer from './reservationChangeStep2/reducer';
import reservationChangeStep3Reducer from './reservationChangeStep3/reducer';
import loginReducer from './login/reducer';
import membershipReducer from './membership/reducer';
import termsReducer from './terms/reducer';
import reservationStep2Reducer from './reservationStep2/reducer';
import reservationStep3Reducer from './reservationStep3/reducer';
import reservationListReducer from './reservationList/reducer';
import stationSearchReducer from './stationSearch/reducer';
import membershipInformationReducer from './membershipInformation/reducer';
import membershipWithdrawalReducer from './membershipWithdrawal/reducer';
import reservationInfoReducer from './reservationInfo/reducer';
import cancelMembershipStep1Reducer from './cancelMembershipStep1/reducer';
import receiptReducer from './receipt/reducer';
import topReducer from './top/reducer';

export default combineReducers({
  login: loginReducer,
  reservationStep1: reservationStepReducer,
  reservationChangeStep1: reservationChangeStep1Reducer,
  reservationChangeStep2: reservationChangeStep2Reducer,
  reservationChangeStep3: reservationChangeStep3Reducer,
  membership: membershipReducer,
  terms: termsReducer,
  reservationStep2Reducer: reservationStep2Reducer,
  reservationStep3: reservationStep3Reducer,
  reservationListReducer: reservationListReducer,
  stationSearch: stationSearchReducer,
  membershipInformation: membershipInformationReducer,
  membershipWithdrawal: membershipWithdrawalReducer,
  reservationInfoReducer: reservationInfoReducer,
  cancelMembershipStep1: cancelMembershipStep1Reducer,
  receiptReducer: receiptReducer,
  topReducer: topReducer,
});
