import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { ReservationChangeStep2InitialResponse } from 'ReservationChangeStep2-Types';
import * as actions from './actions';
import { fetchError } from 'ReservationStep1-Types';

const initialDataFetching = createReducer<ReservationChangeStep2InitialResponse>(
  {} as ReservationChangeStep2InitialResponse,
).handleAction(
  actions.fetchReservationChangeStep2InitialAsync.success,
  (state, action) => action.payload,
);

const error = createReducer<fetchError>({} as fetchError).handleAction(
  actions.fetchReservationChangeStep2InitialAsync.failure,
  (_state, action) => action.payload,
);

const reservationChangeStep2Reducer = combineReducers({
  initialDataFetching,
  error,
});

export default reservationChangeStep2Reducer;
