import { combineReducers } from 'redux';
import { ReservationChangeStep3InitialResponse } from 'ReservationChangeStep3-Types';
import { fetchError } from 'ReservationStep1-Types';
import { createReducer } from 'typesafe-actions';
import * as actions from './actions';

const initialDataFetching = createReducer<ReservationChangeStep3InitialResponse>(
  {} as ReservationChangeStep3InitialResponse,
).handleAction(
  actions.fetchReservationChangeStep3InitialAsync.success,
  (state, action) => action.payload,
);

const initialDataFetchingError = createReducer<fetchError>({} as fetchError).handleAction(
  actions.fetchReservationChangeStep3InitialAsync.failure,
  (_state, action) => action.payload,
);

const fromStepTwo = createReducer<boolean>(false).handleAction(
  actions.updateFromStepIsTwo,
  (state, action) => {
    return (state = action.payload);
  },
);

const reservationChangeStep3Reducer = combineReducers({
  initialDataFetching,
  initialDataFetchingError,
  fromStepTwo,
});

export default reservationChangeStep3Reducer;
