type Props = {
  className?: string;
};

export default function AmericanExpressIcon(props: Props) {
  return (
    <span className={props.className}>
      <svg
        width="28"
        height="20"
        viewBox="0 0 28 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.25 2C0.25 1.0335 1.0335 0.25 2 0.25H26C26.9665 0.25 27.75 1.0335 27.75 2V18C27.75 18.9665 26.9665 19.75 26 19.75H2C1.0335 19.75 0.25 18.9665 0.25 18V2Z"
          fill="white"
          stroke="#D1D1D1"
          stroke-width="0.5"
        />
        <path
          d="M21 17V14.781H19.3118L18.4425 13.8203L17.5686 14.781H12.0007V10.302H10.2026L12.4307 5.25556H14.581L15.3497 6.98497V5.25556H18.0124L18.4745 6.55948L18.9412 5.25556H21V3H7V17H21ZM19.5954 14.2183H21L19.1425 12.2464L21 10.2974H19.6183L18.4699 11.5556L17.3353 10.302H15.9307L17.7791 12.2601L15.9307 14.2183H17.2941L18.4471 12.9556L19.5954 14.2183ZM19.9248 12.251L21 13.3948V11.1163L19.9248 12.251ZM13.6614 13.3124V12.7039H15.8712V11.8209H13.6614V11.2124H15.9261V10.3065H12.5908V14.2229H15.9261V13.317H13.6614V13.3124ZM19.9431 9.73464H21V5.8183H19.3529L18.4745 8.25686L17.6007 5.8183H15.9261V9.73464H16.983V6.98954L17.9895 9.73007H18.932L19.9386 6.98039V9.73464H19.9431ZM14.7275 9.73464H15.9307L14.2013 5.8183H12.8242L11.0948 9.73464H12.2706L12.5954 8.95229H14.398L14.7275 9.73464ZM14.0275 8.07386H12.966L13.4967 6.79281L14.0275 8.07386Z"
          fill="#0079C1"
        />
      </svg>
    </span>
  );
}
