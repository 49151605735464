type Props = {
  className?: string;
};
export default function Hamburger(props: Props) {
  return (
    <span className={props.className}>
      <svg
        width="18"
        height="16"
        viewBox="0 0 18 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 2H18V0.5H0V2Z" fill="#686868" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 8.75H18V7.25H0V8.75Z" fill="#686868" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 15.5H18V14H0V15.5Z" fill="#686868" />
      </svg>
    </span>
  );
}
