import { MouseEventHandler } from 'react';

type Props = {
  className?: string;
  onClick?: MouseEventHandler<HTMLSpanElement>;
};

export default function ChevronLeftWhite(props: Props) {
  const { className, ...rest } = props;
  return (
    <span className={className} {...rest}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.5 21.75L17.5605 20.6895L8.871 12L17.5605 3.3105L16.5 2.25L6.75 12L16.5 21.75Z"
          fill="white"
        />
      </svg>
    </span>
  );
}
