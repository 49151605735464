import Request, { HttpMethod } from 'utils/fetch';
import {
  ReservationStep3InitialRequest,
  ReservationStep3InitialResponse,
} from 'ReservationStep3-Types';

export const fetchReservationStep3InitialData = (
  req: ReservationStep3InitialRequest,
): Promise<ReservationStep3InitialResponse> =>
  Request(`/reservation/step3`, {
    method: HttpMethod.post,
    body: req,
  }).then(response => {
    return response;
  });
