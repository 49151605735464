import { ErrorResponse } from 'MembershipInformation-Types';
import { createAsyncAction, AppThunk } from 'typesafe-actions';
import * as api from './api';

export const getMembershipWithdrawalAsync = createAsyncAction(
  'GET_MEMBERSHIP_WITHDRAWAL_REQUEST',
  'GET_MEMBERSHIP_WITHDRAWAL_SUCCESS',
  'GET_MEMBERSHIP_WITHDRAWAL_FAILURE',
)<string, {}, ErrorResponse>();

export const getMembershipWithdrawal = (): AppThunk => async dispatch => {
  dispatch(getMembershipWithdrawalAsync.request(''));
  try {
    dispatch(getMembershipWithdrawalAsync.failure({} as ErrorResponse));
    const response = await api.getMembershipWithdrawal();
    dispatch(getMembershipWithdrawalAsync.success(response));
  } catch (error) {
    dispatch(getMembershipWithdrawalAsync.failure(error as ErrorResponse));
  }
};
