type Props = {
  className?: string;
  color?: string;
};
export default function Location(props: Props) {
  const { className = '', color = '#333333' } = props;

  return (
    <span className={className}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8 7.5C7.448 7.5 7 7.052 7 6.5C7 5.948 7.448 5.5 8 5.5C8.552 5.5 9 5.948 9 6.5C9 7.052 8.552 7.5 8 7.5ZM8 1.5C5.239 1.5 3 3.7385 3 6.5C3 10 8 14.5 8 14.5C8 14.5 13 10 13 6.5C13 3.7385 10.761 1.5 8 1.5ZM8 8.5C9.103 8.5 10 7.603 10 6.5C10 5.397 9.103 4.5 8 4.5C6.897 4.5 6 5.397 6 6.5C6 7.603 6.897 8.5 8 8.5ZM8 2.5C10.2055 2.5 12 4.2945 12 6.5C12 8.528 9.531 11.5105 8 13.094C6.469 11.5105 4 8.528 4 6.5C4 4.2945 5.7945 2.5 8 2.5Z"
          fill={color}
        />
      </svg>
    </span>
  );
}
