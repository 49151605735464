type Props = {
  className?: string;
};

export default function Account(props: Props) {
  return (
    <span className={props.className}>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18.5 0.5V3.5H21.5V5H18.5V8H17V5H14V3.5H17V0.5H18.5ZM2.00631 20.0072L2.00625 20.0121L15.5025 20.0001C15.5019 19.9711 15.5013 19.9405 15.5007 19.9085C15.4764 18.6712 15.4099 15.2815 14.4397 14.3106C13.7872 13.6581 12.612 12.8443 11.1307 12.0193C10.524 11.6818 10.1152 11.0961 10.008 10.4121C9.90075 9.72283 10.1137 9.03433 10.593 8.52283C11.3317 7.73458 11.7502 7.18258 11.7502 5.00008C11.7502 3.61633 10.9642 2.00008 8.74874 2.00008C6.5355 2.00008 5.75025 3.61633 5.75025 5.00008C5.75025 7.18258 6.16799 7.73458 6.90674 8.52283C7.38599 9.03433 7.59975 9.72283 7.49175 10.4121C7.3845 11.0961 6.97575 11.6818 6.36975 12.0193C4.8885 12.8443 3.71325 13.6581 3.06075 14.3106C2.06689 15.3052 2.02137 18.8381 2.00631 20.0072ZM11.6872 9.54808C11.3542 9.90358 11.4345 10.4721 11.8597 10.7091C12.8625 11.2671 14.5087 12.2593 15.5002 13.2501C16.7565 14.5071 16.9605 17.3421 16.9935 19.9933C17.004 20.8243 16.3327 21.5001 15.5025 21.5001H1.99723C1.16698 21.5001 0.495725 20.8243 0.506225 19.9933C0.539225 17.3421 0.743225 14.5071 2.00023 13.2501C2.99098 12.2593 4.63723 11.2671 5.63998 10.7091C6.06598 10.4721 6.14547 9.90358 5.81248 9.54808C4.77073 8.43733 4.25023 7.49983 4.25023 5.00008C4.25023 2.75008 5.75023 0.50008 8.74872 0.50008C11.7502 0.50008 13.2502 2.75008 13.2502 5.00008C13.2502 7.49983 12.729 8.43733 11.6872 9.54808Z"
          fill="#686868"
        />
      </svg>
    </span>
  );
}
