import { createAction, createAsyncAction, AppThunk } from 'typesafe-actions';
import {
  ErrorResponse,
  RakutenMembershipInfo,
  LicenseImage,
  LicenseInfo,
  EmergencyInfo,
  UserInfo,
  AllUserInfo,
  PointResponse,
  LoginType,
  Step3Response,
} from 'Membership-Types';
import * as membershipApi from './api';
import { LoginStatus } from 'Login-Types';
import { updateUsablePoints } from 'store/login/actions';

export const fetchUserInfoAsync = createAsyncAction(
  'FETCH_USER_INFO_REQUEST',
  'FETCH_USER_INFO_SUCCESS',
  'FETCH_USER_INFO_FAILURE',
)<string, RakutenMembershipInfo, ErrorResponse>();

export const fetchUserInfo = (loginType: LoginType): AppThunk => async dispatch => {
  dispatch(fetchUserInfoAsync.request(''));
  dispatch(fetchUserInfoAsync.failure({} as ErrorResponse));
  try {
    dispatch(fetchUserInfoAsync.success({} as RakutenMembershipInfo));
    const response: RakutenMembershipInfo = await membershipApi.getMembershipStep1(loginType);
    dispatch(fetchUserInfoAsync.success(response));
  } catch (error) {
    dispatch(fetchUserInfoAsync.failure(error as ErrorResponse));
  }
};

export const checkUserInfoAsync = createAsyncAction(
  'CHECK_USER_INFO_REQUEST',
  'CHECK_USER_INFO_SUCCESS',
  'CHECK_USER_INFO_FAILURE',
)<string, UserInfo, ErrorResponse>();

export const checkUserInfo = (req: UserInfo): AppThunk => async dispatch => {
  dispatch(checkUserInfoAsync.request(''));
  dispatch(checkUserInfoAsync.failure({} as ErrorResponse));
  try {
    const response: UserInfo = await membershipApi.postMembershipStep2(req);
    dispatch(checkUserInfoAsync.success(response));
  } catch (error) {
    dispatch(checkUserInfoAsync.failure(error as ErrorResponse));
  }
};

export const saveUserInfoAsync = createAsyncAction(
  'SAVE_USER_INFO_REQUEST',
  'SAVE_USER_INFO_SUCCESS',
  'SAVE_USER_INFO_FAILURE',
)<string, Step3Response, ErrorResponse>();

export const saveUserInfo = (req: AllUserInfo): AppThunk => async dispatch => {
  dispatch(saveUserInfoAsync.request(''));
  dispatch(saveUserInfoAsync.failure({} as ErrorResponse));
  try {
    const response = await membershipApi.postMembershipStep3(req);
    dispatch(saveUserInfoAsync.success(response));
  } catch (error) {
    dispatch(saveUserInfoAsync.failure(error as ErrorResponse));
  }
};

export const getMemberShipLoginStatusAsync = createAsyncAction(
  'GET_MEMBERSHIP_LOGIN_STATUS_REQUEST',
  'GET_MEMBERSHIP_LOGIN_STATUS_SUCCESS',
  'GET_MEMBERSHIP_LOGIN_STATUS_FAILURE',
)<string, LoginStatus, ErrorResponse>();

export const getStatusInfo = (): AppThunk => async dispatch => {
  dispatch(getMemberShipLoginStatusAsync.request(''));
  dispatch(getMemberShipLoginStatusAsync.failure({} as ErrorResponse));
  try {
    dispatch(getMemberShipLoginStatusAsync.success({} as LoginStatus));
    const response: LoginStatus = await membershipApi.getMembershipLoginStatus();
    dispatch(getMemberShipLoginStatusAsync.success(response));
  } catch (error) {
    dispatch(getMemberShipLoginStatusAsync.failure(error as ErrorResponse));
  }
};
export const getPointAsync = createAsyncAction(
  'GET_MEMBERSHIP_POINT_REQUEST',
  'GET_MEMBERSHIP_POINT_SUCCESS',
  'GET_MEMBERSHIP_POINT_FAILURE',
)<string, PointResponse, ErrorResponse>();

export const updatePointInfo = (): AppThunk => async dispatch => {
  dispatch(getPointAsync.request(''));
  dispatch(getPointAsync.failure({} as ErrorResponse));
  try {
    const response: PointResponse = await membershipApi.getPoint();
    dispatch(
      updateUsablePoints(
        response.point.fixedPoint + response.point.rcashPoint + response.point.termPoint,
      ),
    );
  } catch (error) {
    dispatch(getPointAsync.failure(error as ErrorResponse));
  }
};
export const updateUserInfo = createAction('UPDATE_USER_INFO')<Partial<RakutenMembershipInfo>>();

export const updateLicenseImage = createAction('UPDATE_LICENSE_IMAGE')<Partial<LicenseImage>>();

export const updateLicenseInfo = createAction('UPDATE_LICENSE_INFO')<Partial<LicenseInfo>>();

export const updateEmergencyInfo = createAction('UPDATE_EMERGENCY_INFO')<Partial<EmergencyInfo>>();

export const clearUserInfo = createAction('CLEAR_USER_INFO')<Partial<RakutenMembershipInfo>>();

export const clearLicenseImage = createAction('CLEAR_LICENSE_IMAGE')<Partial<LicenseImage>>();

export const clearLicenseInfo = createAction('CLEAR_LICENSE_INFO')<Partial<LicenseInfo>>();

export const clearEmergencyInfo = createAction('CLEAR_EMERGENCY_INFO')<Partial<EmergencyInfo>>();

export const clearMembershipInfo = createAction('CLEAR_MEMBERSHIP_INFO')<
  Partial<RakutenMembershipInfo>
>();
