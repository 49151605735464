type Props = {
  className?: string;
  color?: string;
};

export default function NewWindow(props: Props) {
  const { className, color = '#333333' } = props;
  return (
    <span className={className}>
      <svg
        width="15"
        height="12"
        viewBox="0 0 15 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.5 1.99985H4.49997V0.999847H13.5V1.99985ZM13.5 7.49979H4.49997V2.99979H13.5V7.49979ZM4.50002 -6.10352e-05H13.5C14.052 -6.10352e-05 14.5 0.447939 14.5 0.999939V7.49994C14.5 8.05194 14.052 8.49994 13.5 8.49994H4.50002C3.94802 8.49994 3.50002 8.05194 3.50002 7.49994V0.999939C3.50002 0.447939 3.94802 -6.10352e-05 4.50002 -6.10352e-05ZM1.5 10.9999H10.5V9.49994H11.5V10.9999C11.5 11.5519 11.052 11.9999 10.5 11.9999H1.5C0.948 11.9999 0.5 11.5519 0.5 10.9999V4.49994C0.5 3.94794 0.948 3.49994 1.5 3.49994H2.5V4.49994H1.5V10.9999Z"
          fill={color}
        />
      </svg>
    </span>
  );
}
