import Request, { HttpMethod } from 'utils/fetch';
import {
  AllUserInfo,
  LoginType,
  PointResponse,
  RakutenMembershipInfo,
  Step3Response,
  UserInfo,
} from 'Membership-Types';
import { LoginStatus } from 'Login-Types';

export const getMembershipStep1 = (loginType: LoginType): Promise<RakutenMembershipInfo> =>
  Request(`/membership/step1`, {
    method: HttpMethod.get,
    body: loginType,
  }).then((data: RakutenMembershipInfo) => {
    return data;
  });

export const postMembershipStep2 = (req: UserInfo): Promise<UserInfo> =>
  Request(`/membership/step2`, {
    method: HttpMethod.post,
    body: req,
  }).then((data: UserInfo) => {
    return data;
  });

export const postMembershipStep3 = (req: AllUserInfo): Promise<Step3Response> =>
  Request(`/membership/step3`, {
    method: HttpMethod.post,
    body: req,
  }).then(data => {
    return data;
  });

export const getMembershipLoginStatus = (): Promise<LoginStatus> =>
  Request(`/membership/status`, {
    method: HttpMethod.get,
  }).then((data: LoginStatus) => {
    return data;
  });
export const getPoint = (): Promise<PointResponse> =>
  Request(`/member/point`, {
    method: HttpMethod.get,
  }).then((data: PointResponse) => {
    return data;
  });
