import { CheckResultResponse, ErrorResponse } from 'CancelMembershipStep1-Types';
import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import * as actions from './actions';

export const isFetchingShouldCancel = createReducer(false as boolean)
  .handleAction([actions.checkReservationAsync.request], () => true)
  .handleAction(
    [actions.checkReservationAsync.success, actions.checkReservationAsync.failure],
    () => false,
  );
export const shouldCancel = createReducer({} as CheckResultResponse).handleAction(
  actions.checkReservationAsync.success,
  (_state, action) => action.payload,
);

export const shouldCancelError = createReducer({} as ErrorResponse).handleAction(
  actions.checkReservationAsync.failure,
  (state, action) => action.payload,
);

export const isFetchingCancelMembershipStep1 = createReducer(false as boolean)
  .handleAction([actions.cancelMembershipStep1Async.request], () => true)
  .handleAction(
    [actions.cancelMembershipStep1Async.success, actions.cancelMembershipStep1Async.failure],
    () => false,
  );
export const cancelMembershipStep1 = createReducer({} as any).handleAction(
  actions.cancelMembershipStep1Async.success,
  (_state, action) => action.payload,
);

export const cancelMembershipStep1Error = createReducer({} as ErrorResponse).handleAction(
  actions.cancelMembershipStep1Async.failure,
  (state, action) => action.payload,
);

const cancelMembershipStep1Reducer = combineReducers({
  isFetchingShouldCancel,
  shouldCancel,
  shouldCancelError,
  isFetchingCancelMembershipStep1,
  cancelMembershipStep1,
  cancelMembershipStep1Error,
});

export default cancelMembershipStep1Reducer;
export type CancelMembershipStep1State = ReturnType<typeof cancelMembershipStep1Reducer>;
