type Props = {
  className?: string;
};

export default function JCBIcon(props: Props) {
  return (
    <span className={props.className}>
      <svg
        width="28"
        height="20"
        viewBox="0 0 28 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.25 2C0.25 1.0335 1.0335 0.25 2 0.25H26C26.9665 0.25 27.75 1.0335 27.75 2V18C27.75 18.9665 26.9665 19.75 26 19.75H2C1.0335 19.75 0.25 18.9665 0.25 18V2Z"
          fill="white"
          stroke="#D1D1D1"
          stroke-width="0.5"
        />
        <path
          d="M23 14.1778C23 15.7333 21.7457 17 20.2054 17H5V5.82222C5 4.26667 6.25428 3 7.79462 3H23V14.1778Z"
          fill="white"
        />
        <path
          d="M18.0488 11.2945H19.2041C19.2371 11.2945 19.3141 11.2835 19.3471 11.2835C19.5672 11.2395 19.7542 11.0415 19.7542 10.7664C19.7542 10.5023 19.5672 10.3043 19.3471 10.2493C19.3141 10.2383 19.2481 10.2383 19.2041 10.2383H18.0488V11.2945Z"
          fill="url(#paint0_linear_25902_1786)"
        />
        <path
          d="M19 4C17.8864 4 17 4.88909 17 6V8.08066H19.8731C19.9399 8.08066 20.0178 8.08066 20.0735 8.09166C20.7194 8.12466 21.1982 8.45463 21.1982 9.02658C21.1982 9.47754 20.8753 9.86251 20.2739 9.93951V9.9615C20.931 10.0055 21.4321 10.3685 21.4321 10.9294C21.4321 11.5344 20.8753 11.9303 20.1403 11.9303H17V13.9652V16H20C21.1136 16 22 15.1091 22 13.9982V4H19Z"
          fill="url(#paint1_linear_25902_1786)"
        />
        <path
          d="M19.6112 9.15989C19.6112 8.89583 19.4241 8.71979 19.2041 8.68678C19.1821 8.68678 19.1271 8.67578 19.0941 8.67578H18.0488V9.644H19.0941C19.1271 9.644 19.1931 9.644 19.2041 9.63299C19.4241 9.59999 19.6112 9.42395 19.6112 9.15989Z"
          fill="url(#paint2_linear_25902_1786)"
        />
        <path
          d="M8 4C6.88641 4 6 4.89174 6 6.00367V10.9468C6.56793 11.222 7.16927 11.3982 7.75947 11.3982C8.46102 11.3982 8.83964 10.9798 8.83964 10.4073V8.07339H10.5768V10.3963C10.5768 11.2991 10.0089 12.0367 8.08241 12.0367C6.91314 12.0367 6 11.7835 6 11.7835V16H9C10.1136 16 11 15.1083 11 13.9963V4H8Z"
          fill="url(#paint3_linear_25902_1786)"
        />
        <path
          d="M13.5 4C12.3998 4 11.5 4.8912 11.5 6.00245V8.62103C12.0061 8.19193 12.9218 7.91687 14.3411 7.98289C15.1003 8.01589 15.9144 8.22494 15.9144 8.22494V9.07213C15.5073 8.86308 15.0232 8.67604 14.3961 8.63203C13.3179 8.55501 12.6687 9.08313 12.6687 10.0073C12.6687 10.9425 13.3179 11.4707 14.3961 11.3826C15.0232 11.3386 15.5073 11.1406 15.9144 10.9425V11.7897C15.9144 11.7897 15.1113 11.9988 14.3411 12.0318C12.9218 12.0978 12.0061 11.8227 11.5 11.3936V16H14.5C15.6002 16 16.5 15.1112 16.5 14V9V4H13.5Z"
          fill="url(#paint4_linear_25902_1786)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_25902_1786"
            x1="17.0678"
            y1="10.7677"
            x2="22.012"
            y2="10.7677"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#007940" />
            <stop offset="0.2285" stop-color="#00873F" />
            <stop offset="0.7433" stop-color="#40A737" />
            <stop offset="1" stop-color="#5CB531" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_25902_1786"
            x1="17.0091"
            y1="9.9952"
            x2="22.0137"
            y2="9.9952"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#007940" />
            <stop offset="0.2285" stop-color="#00873F" />
            <stop offset="0.7433" stop-color="#40A737" />
            <stop offset="1" stop-color="#5CB531" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_25902_1786"
            x1="17.0676"
            y1="9.15851"
            x2="22.0121"
            y2="9.15851"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#007940" />
            <stop offset="0.2285" stop-color="#00873F" />
            <stop offset="0.7433" stop-color="#40A737" />
            <stop offset="1" stop-color="#5CB531" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_25902_1786"
            x1="6.00878"
            y1="10.0007"
            x2="11.0904"
            y2="10.0007"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#1F286F" />
            <stop offset="0.4751" stop-color="#004E94" />
            <stop offset="0.8261" stop-color="#0066B1" />
            <stop offset="1" stop-color="#006FBC" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_25902_1786"
            x1="11.507"
            y1="9.99703"
            x2="16.3833"
            y2="9.99703"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#6C2C2F" />
            <stop offset="0.1735" stop-color="#882730" />
            <stop offset="0.5731" stop-color="#BE1833" />
            <stop offset="0.8585" stop-color="#DC0436" />
            <stop offset="1" stop-color="#E60039" />
          </linearGradient>
        </defs>
      </svg>
    </span>
  );
}
