import { LoginData } from 'Login-Types';
import { createAction } from 'typesafe-actions';

export const loginUser = createAction('LOGIN_USER')<LoginData>();

export const logoutUser = createAction('LOGOUT_USER')<LoginData>();

export const updateUsablePoints = createAction('UPDATE_USABLE_POINTS')<number>();

export const updateMembershipStatus = createAction('UPDATE_MEMBERSHIP_STATUS')<number>();
