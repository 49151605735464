type Props = {
  className?: string;
};

export default function Alert(props: Props) {
  return (
    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.99986 11C6.58586 11 6.24986 10.664 6.24986 10.25C6.24986 9.836 6.58586 9.5 6.99986 9.5C7.41386 9.5 7.74986 9.836 7.74986 10.25C7.74986 10.664 7.41386 11 6.99986 11ZM6.49986 8.5H7.49986V4H6.49986V8.5ZM13.9099 11.9685L7.62486 0.86C7.48936 0.62 7.24436 0.5 6.99986 0.5C6.75536 0.5 6.51036 0.62 6.37486 0.86L0.0898626 11.9685C-0.172637 12.432 0.171363 13 0.714863 13H13.2849C13.8284 13 14.1724 12.432 13.9099 11.9685Z"
        fill="#DF0101"
      />
    </svg>
  );
}
