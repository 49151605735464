import { MouseEventHandler } from 'react';

type Props = {
  className?: string;
  onClick?: MouseEventHandler<HTMLSpanElement>;
};

export default function Reload(props: Props) {
  const { className, onClick } = props;
  return (
    <span className={className} onClick={onClick}>
      <svg
        width="375"
        height="24"
        viewBox="0 0 375 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M195.25 12C195.25 16.5487 191.549 20.25 187 20.25C182.451 20.25 178.75 16.5487 178.75 12C178.75 7.45125 182.451 3.75 187 3.75C189.319 3.75 191.481 4.713 193.041 6.3975L191.19 8.25H196V3.4395L194.102 5.337C192.262 3.37125 189.722 2.25 187 2.25C181.624 2.25 177.25 6.624 177.25 12C177.25 17.376 181.624 21.75 187 21.75C192.376 21.75 196.75 17.376 196.75 12H195.25Z"
          fill="white"
        />
      </svg>
    </span>
  );
}
