import Request, { HttpMethod } from 'utils/fetch';
import {
  ReservationChangeStep3InitialRequest,
  ReservationChangeStep3InitialResponse,
} from 'ReservationChangeStep3-Types';

export const fetchReservationChangeStep3InitialData = (
  req: ReservationChangeStep3InitialRequest,
): Promise<ReservationChangeStep3InitialResponse> =>
  Request(`/reservation/change/step3`, {
    method: HttpMethod.post,
    body: req,
  }).then(response => {
    return response;
  });
