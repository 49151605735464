type Props = {
  className?: string;
  onClick?: () => void;
  isCardNumber: boolean;
};

export default function Union(props: Props) {
  const { className, onClick, isCardNumber } = props;
  return (
    <span onClick={onClick}>
      {!isCardNumber ? (
        <svg
          className={className}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.99994 11.9999C4.44894 11.9999 2.63944 9.06244 2.10144 7.99994C2.41794 7.37544 3.17594 6.10994 4.46094 5.16794L5.91594 6.62294C5.65394 7.01794 5.49994 7.49044 5.49994 7.99994C5.49994 9.38044 6.61894 10.4999 7.99994 10.4999C8.50944 10.4999 8.98194 10.3454 9.37694 10.0839L10.6624 11.3694C9.91294 11.7474 9.03194 11.9999 7.99994 11.9999ZM7.99994 3.99994C11.5509 3.99994 13.3604 6.93794 13.8984 7.99994C13.5819 8.62444 12.8239 9.88994 11.5389 10.8319L10.0839 9.37694C10.3454 8.98194 10.4999 8.50944 10.4999 7.99994C10.4999 6.61894 9.38044 5.49994 7.99994 5.49994C7.49044 5.49994 7.01794 5.65394 6.62294 5.91594L5.33794 4.63044C6.08694 4.25244 6.96794 3.99994 7.99994 3.99994ZM9.34944 8.64244L8.35344 7.64644L7.35744 6.64994C7.55294 6.55644 7.76894 6.49994 7.99994 6.49994C8.82694 6.49994 9.49994 7.17294 9.49994 7.99994C9.49994 8.23044 9.44294 8.44694 9.34944 8.64244ZM6.64994 7.35744L8.64244 9.34944C8.44694 9.44294 8.23044 9.49994 7.99994 9.49994C7.17294 9.49994 6.49994 8.82694 6.49994 7.99994C6.49994 7.76894 6.55644 7.55294 6.64994 7.35744ZM15.1014 14.3944L12.2499 11.5429C14.1709 10.0669 14.9999 7.99994 14.9999 7.99994C14.9999 7.99994 12.9999 2.99994 7.99994 2.99994C6.65544 2.99994 5.52944 3.36344 4.59944 3.89194L1.60544 0.898438L0.898438 1.60544L3.74994 4.45694C1.82894 5.93294 0.999937 7.99994 0.999937 7.99994C0.999937 7.99994 2.99994 12.9999 7.99994 12.9999C9.34444 12.9999 10.4704 12.6364 11.4009 12.1079L14.3944 15.1014L15.1014 14.3944Z"
            fill="#606060"
          />
        </svg>
      ) : (
        <svg
          className={className}
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 18C6.67726 18 3.96376 13.5998 3.15451 12.0037C3.96601 10.422 6.70876 6 12 6C17.3228 6 20.037 10.4002 20.8455 11.9963C20.034 13.578 17.2913 18 12 18ZM12 4.5C4.5 4.5 1.5 12 1.5 12C1.5 12 4.5 19.5 12 19.5C19.5 19.5 22.5 12 22.5 12C22.5 12 19.5 4.5 12 4.5Z"
            fill="#606060"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 14.25C10.7595 14.25 9.75 13.2405 9.75 12C9.75 10.7595 10.7595 9.75 12 9.75C13.2405 9.75 14.25 10.7595 14.25 12C14.25 13.2405 13.2405 14.25 12 14.25ZM12 8.25C9.9285 8.25 8.25 9.9285 8.25 12C8.25 14.0707 9.9285 15.75 12 15.75C14.0707 15.75 15.75 14.0707 15.75 12C15.75 9.9285 14.0707 8.25 12 8.25Z"
            fill="#606060"
          />
        </svg>
      )}
    </span>
  );
}
