import { createAsyncAction, AppThunk } from 'typesafe-actions';
import {
  CalculatePriceRequest,
  CalculatePriceResponse,
  CardListResponse,
  CheckPartnerTermsRequest,
  CheckPartnerTermsResponse,
  ReservationStep2RetData,
  ReservationStepError,
  SearchStep2RequestBody,
  CheckPointInfoRequest,
  CheckPointInfoResponse,
  CheckCreditCardInfoRequest,
  CheckCreditCardInfoResponse,
  PointResponse,
  CouponsListResponse,
  CouponsListRequest,
} from 'ReservationStep2-Types';
import {
  fetchCalculatePriceData,
  fetchCheckPartnerTerms,
  fetchReservationStep2Data,
  fetchCardListData,
  fetchCheckPointInfoData,
  fetchCheckCreditCardInfoData,
  fetchPointData,
  fetchCouponsListData,
} from './api';

export const fetchCalculatePriceAsync = createAsyncAction(
  'FETCH_CALCULATE_REQUEST',
  'FETCH_CALCULATE_SUCCESS',
  'FETCH_CALCULATE_FAILURE',
)<undefined, CalculatePriceResponse, ReservationStepError>();

export const fetchCalculatePrice = (req: CalculatePriceRequest): AppThunk => async dispatch => {
  dispatch(fetchCalculatePriceAsync.request());
  try {
    const response = await fetchCalculatePriceData(req);
    dispatch(fetchCalculatePriceAsync.success(response));
    dispatch(fetchCalculatePriceAsync.failure({} as ReservationStepError));
  } catch (error) {
    dispatch(fetchCalculatePriceAsync.failure(error as ReservationStepError));
  }
};

export const fetchReservationStep2Async = createAsyncAction(
  'FETCH_STEP2_REQUEST',
  'FETCH_STEP2_SUCCESS',
  'FETCH_STEP2_FAILURE',
)<undefined, ReservationStep2RetData, ReservationStepError>();

export const fetchReservationStep2 = (req: SearchStep2RequestBody): AppThunk => async dispatch => {
  dispatch(fetchReservationStep2Async.request());
  try {
    const response = await fetchReservationStep2Data(req);
    dispatch(fetchReservationStep2Async.success(response));
    dispatch(fetchReservationStep2Async.failure({} as ReservationStepError));
  } catch (error) {
    dispatch(fetchReservationStep2Async.failure(error as ReservationStepError));
  }
};

export const fetchCheckPartnerTermsAsync = createAsyncAction(
  'FETCH_CHECK_REQUEST',
  'FETCH_CHECK_SUCCESS',
  'FETCH_CHECK_FAILURE',
)<undefined, CheckPartnerTermsResponse, ReservationStepError>();

export const fetchStep2CheckPartnerTerms = (
  req: CheckPartnerTermsRequest,
): AppThunk => async dispatch => {
  dispatch(fetchCheckPartnerTermsAsync.request());
  try {
    const response = await fetchCheckPartnerTerms(req);
    dispatch(fetchCheckPartnerTermsAsync.success(response));
    dispatch(fetchCheckPartnerTermsAsync.failure({} as ReservationStepError));
  } catch (error) {
    dispatch(fetchCheckPartnerTermsAsync.failure(error as ReservationStepError));
  }
};

export const fetchCardListAsync = createAsyncAction(
  'FETCH_CARD_REQUEST',
  'FETCH_CARD_SUCCESS',
  'FETCH_CARD_FAILURE',
)<undefined, CardListResponse, ReservationStepError>();

export const fetchCardList = (): AppThunk => async dispatch => {
  dispatch(fetchCardListAsync.request());
  try {
    const response = await fetchCardListData();
    dispatch(fetchCardListAsync.success(response));
    dispatch(fetchCardListAsync.failure({} as ReservationStepError));
  } catch (error) {
    dispatch(fetchCardListAsync.failure(error as ReservationStepError));
  }
};
export const fetchPointAsync = createAsyncAction(
  'FETCH_POINT_REQUEST',
  'FETCH_POINT_SUCCESS',
  'FETCH_POINT_FAILURE',
)<undefined, PointResponse, ReservationStepError>();

export const fetchPointList = (): AppThunk => async dispatch => {
  dispatch(fetchPointAsync.request());
  try {
    const response = await fetchPointData();
    dispatch(fetchPointAsync.success(response));
    dispatch(fetchPointAsync.failure({} as ReservationStepError));
  } catch (error) {
    dispatch(fetchPointAsync.failure(error as ReservationStepError));
  }
};
export const fetchCheckPointInfoAsync = createAsyncAction(
  'FETCH_CHECK_POINT_REQUEST',
  'FETCH_CHECK_POINT_SUCCESS',
  'FETCH_CHECK_POINT_FAILURE',
)<undefined, CheckPointInfoResponse, ReservationStepError>();

export const fetchCheckPointInfo = (req: CheckPointInfoRequest): AppThunk => async dispatch => {
  dispatch(fetchCheckPointInfoAsync.request());
  try {
    const response = await fetchCheckPointInfoData(req);
    dispatch(fetchCheckPointInfoAsync.success(response));
    dispatch(fetchCheckPointInfoAsync.failure({} as ReservationStepError));
  } catch (error) {
    dispatch(fetchCheckPointInfoAsync.failure(error as ReservationStepError));
  }
};
export const fetchCheckCreditCardInfoAsync = createAsyncAction(
  'FETCH_CHECK_CREDIT_CARD_REQUEST',
  'FETCH_CHECK_CREDIT_CARD_SUCCESS',
  'FETCH_CHECK_CREDIT_CARD_FAILURE',
)<undefined, CheckCreditCardInfoResponse, ReservationStepError>();

export const fetchCheckCreditCardInfo = (
  req: CheckCreditCardInfoRequest,
): AppThunk => async dispatch => {
  dispatch(fetchCheckCreditCardInfoAsync.request());
  try {
    const response = await fetchCheckCreditCardInfoData(req);
    dispatch(fetchCheckCreditCardInfoAsync.success(response));
    dispatch(fetchCheckCreditCardInfoAsync.failure({} as ReservationStepError));
  } catch (error) {
    dispatch(fetchCheckCreditCardInfoAsync.failure(error as ReservationStepError));
  }
};

export const fetchCouponsListAsync = createAsyncAction(
  'FETCH_COUPONS_LIST_REQUEST',
  'FETCH_COUPONS_LIST_SUCCESS',
  'FETCH_COUPONS_LIST_FAILURE',
)<undefined, CouponsListResponse, ReservationStepError>();

export const fetchCouponsList = (req: CouponsListRequest): AppThunk => async dispatch => {
  dispatch(fetchCouponsListAsync.request());
  try {
    const response = await fetchCouponsListData(req);
    dispatch(fetchCouponsListAsync.success(response));
    dispatch(fetchCouponsListAsync.failure({} as ReservationStepError));
  } catch (error) {
    dispatch(fetchCouponsListAsync.failure(error as ReservationStepError));
  }
};
