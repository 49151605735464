import { createReducer } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { LoginData } from 'Login-Types';
import * as actions from './actions';

export const login = createReducer({} as LoginData)
  .handleAction([actions.loginUser, actions.logoutUser], (_, action) => action.payload)
  .handleAction([actions.updateUsablePoints], (state, action) => {
    return { ...state, usablePoints: action.payload };
  })
  .handleAction([actions.updateMembershipStatus], (state, action) => {
    return { ...state, membershipStatus: action.payload };
  });

const reducers = combineReducers({ login });
export default reducers;
