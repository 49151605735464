import { CaritecoIcon, OrixIcon } from 'icons';
import moment from 'moment';
import { Reservation } from 'ReservationList-Types';
export const reservationListInit: Array<Reservation> = [];
export const reservationListInitData = {
  reservationList: { offset: 0, limit: 30, total: 0, contents: reservationListInit },
};
const noteList = [
  {
    note: '',
    dispOrder: 1,
  },
];

const cancelPolicy = [
  {
    conditions: '',
    description: '',
    remarks: '',
    dispOrder: 1,
  },
];
const cardInformation = {
  brandCode: '',
  cardHolderName: '',
  last4Digits: '0',
  expirationMonth: 0,
  expirationYear: 0,
};
const couponUsedList = [
  {
    code: '',
    name: '',
    discountAmount: 0,
  },
];
const priceList = [
  {
    name: '',
    unitPrice: 0,
    count: 0,
    subTotalPrice: 0,
  },
];
const distancePrice = {
  hasDistancePrice: false,
  generatedHour: 0,
  unitPrice: 0,
};
const reservation = {
  reservationId: '',
  reservationDate: '',
  cancelDate: '',
  partnerId: 0,
  partnerName: '',
  stationId: 0,
  stationName: '',
  address: '',
  lat: 0,
  lng: 0,
  imageUrl: '',
  imageDescription: '',
  access: '',
  info: '',
  memo: '',
  businessTime: '',
  carTypeId: '',
  carTypeName: '',
  carImageUrl: '',
  carClassId: '',
  carClassName: '',
  capacity: 0,
  carNo: '',
  reservationStatus: 0,
  fixedAmountFlg: false,
  paymentSettledFlg: false,
  issuedCnt: 0,
  registeredElectronicKeyFlg: false,
  departureDateTime: moment()
    .local()
    .format('YYYY-MM-DD HH:mm'),
  returnDateTime: moment()
    .local()
    .format('YYYY-MM-DD HH:mm'),
  actualDepartureDateTime: moment()
    .local()
    .format('YYYY-MM-DD HH:mm'),
  actualReturnDateTime: moment()
    .local()
    .format('YYYY-MM-DD HH:mm'),
  usagePoint: 0,
  expectedTotalAmount: 0,
  expectedCancelAmount: 0,
  distance: 0,
  actualTotalAmount: 0,
  actualBasicAmount: 0,
  actualTimeExcessAmount: 0,
  actualDistanceAmount: 0,
  actualOtherAmount: 0,
  actualCancelAmount: 0,
  cardInformation: cardInformation,
  couponUsedList: couponUsedList,
  priceList: priceList,
  distancePrice: distancePrice,
};
export const reInfoInitData = {
  noteList: noteList,
  cancelPolicy: cancelPolicy,
  reservationInfo: reservation,
};

export const calculatePriceInitReducer = {
  totalPrice: 0,
  hasDistancePrice: false,
  distanceUnitPrice: 0,
  usagePoint: 0,
  grantPoint: 0,
  nightPackFlg: false,
  priceList: [
    {
      id: 1,
      name: '',
      unitPrice: 0,
      count: 0,
      subTotalPrice: 0,
    },
  ],
  coupons: [{ code: '', name: '', discountAmount: 0 }],
};

export const priceWithCouponInitReducer = {
  priceInfo: {
    totalPrice: 0,
    distanceUnitPrice: 0,
    hasDistancePrice: false,
    usagePoint: 0,
    grantPoint: 0,
    nightPackFlg: false,
    priceList: [{ id: 0, name: '', unitPrice: 0, count: 0, subTotalPrice: 0 }],
    coupons: [{ code: '', name: '', discountAmount: 0 }],
  },
  coupons: [
    {
      code: '',
      endDate: '',
      discountAmount: 0,
      issuedCnt: 0,
      acquiredStatus: 0,
      key: '',
      pcGetUrl: '',
      departureDateTime: '',
      returnDateTime: '',
      lowPrice: 0,
    },
  ],
};

export const checkPartnerTermsInitReducer = {
  termCheck: false,
};
const Partner = {
  id: 0,
  name: '',
  officialName: '',
  reservationUnitMinutes: 0,
  reservableDays: 0,
  maxReturnableDays: 0,
  minReservationMinutes: 0,
  distanceChargeGeneratedHour: 0,
};
const Station = {
  Id: '',
  name: '',
  address: '',
  lat: 0,
  lng: 0,
  access: '',
  id: '',
  memo: '',
  businessTime: '',
  info: '',
  imageUrl: '',
  imageDescription: '',
};
const Car = {
  id: '',
  imageUrl: '',
  name: '',
  classId: '',
  typeId: '',
  className: '',
  capacity: 0,
  remarks: [],
};
const PriceInfo = {
  totalPrice: 0,
  distanceUnitPrice: 0,
  hasDistancePrice: false,
  usagePoint: 0,
  grantPoint: 0,
  nightPackFlg: false,
  priceList: [
    {
      id: 0,
      name: '',
      unitPrice: 0,
      count: 0,
      subTotalPrice: 0,
    },
  ],
  coupons: [
    {
      code: '',
      name: '',
      discountAmount: 0,
    },
  ],
};
const ReservationInfo = {
  departureDateTime: moment()
    .local()
    .format('YYYY-MM-DD HH:mm'),
  returnDateTime: moment()
    .local()
    .format('YYYY-MM-DD HH:mm'),
  partner: Partner,
  station: Station,
  car: Car,
  noteList: [
    {
      note: '',
      dispOrder: 1,
    },
  ],
};
const MemberShipInfo = {
  licenseNumber: '',
  lastName: '',
  firstName: '',
  zipCode: '',
  prefecture: '',
  city: '',
  street: '',
  emailAddress: '',
  mobilePhone1: '',
  mobilePhone2: '',
  mobilePhone3: '',
  emergencyTel1: '',
  emergencyTel2: '',
  emergencyTel3: '',
  status: '',
};
const Point = {
  ablePoint: 0,
  termPoint: 0,
  rcashPoint: 0,
  fixedPoint: 0,
};
const CreditInfo = {
  brandCode: '',
  cardHolderName: '',
  expirationMonth: '',
  expirationYear: '',
  last4digits: '',
  iin: '',
  cardToken: '',
};

const CancelPolicy = [
  {
    conditions: '',
    description: '',
    remarks: '',
    dispOrder: 0,
  },
];

const Coupon = {
  code: '',
  key: '',
  name: '',
  discountAmount: 0,
  availableCouponCount: 0,
  errorMessage: '',
};

const Time = [
  {
    name: '',
    price: 0,
    unit: 0,
    type: 0,
    displayOrder: 1,
    dateFrom: '',
    dateTo: '',
  },
];

const Distance = [{ name: '', price: 0, dateFrom: '', dateTo: '' }];

const PriceLists = {
  carClass: [
    {
      partnerId: 0,
      carClassId: '',
      time: Time,
      newTimePrice: Time,
      distance: Distance,
      newDistancePrice: Distance,
    },
  ],
  noteList: [
    {
      note: '',
    },
  ],
};

export const reStep2InitReducer = {
  reservationInfo: ReservationInfo,
  priceInfo: PriceInfo,
  cancelPolicy: CancelPolicy,
  member: MemberShipInfo,
  point: Point,
  creditInfo: CreditInfo,
  autoApplyCoupon: Coupon,
  priceList: PriceLists,
};

export const reservationStep2InitData = {
  reservationInfo: ReservationInfo,
  priceInfo: PriceInfo,
  cancelPolicy: CancelPolicy,
  member: MemberShipInfo,
  point: Point,
  creditInfo: CreditInfo,
  autoApplyCoupon: Coupon,
  priceList: PriceLists,
};
const CardList = [
  {
    brandCode: '',
    cardHolderName: '',
    expirationMonth: '',
    expirationYear: '',
    last4digits: '',
    iin: '',
    cardToken: '',
  },
];
export const reCardListInitReducer = {
  easyId: '',
  emailAddress: '',
  firstName: '',
  lastName: '',
  firstNameKatakana: '',
  lastNameKatakana: '',
  zipCode: '',
  prefecture: '',
  city: '',
  street: '',
  mobilePhone1: '',
  mobilePhone2: '',
  mobilePhone3: '',
  birthDate: '',
  sex: '',
  cardList: CardList,
};
export const rePointInitReducer = {
  point: Point,
};

const reStep1Info = {
  departureDateTime: '',
  returnDateTime: '',
  partner: Partner,
  station: Station,
  car: Car,
  noteList: [
    {
      note: '',
      dispOrder: 1,
    },
  ],
};

const UnacceptedList = [
  {
    reservationDate: 0,
    startDateTime: '',
    endDateTime: '',
  },
];

const CouponList = [
  {
    code: '',
    endDate: '',
    discountAmount: 0,
    issuedCnt: 0,
    acquiredStatus: 0,
    key: '',
    pcGetUrl: '',
    departureDateTime: '',
    returnDateTime: '',
    lowPrice: 0,
  },
];

const ReservationPrice = {
  priceInfo: PriceInfo,
  coupons: CouponList,
};

const NightPack = {
  showNightPack: false,
  descriptions: [],
  notes: [],
};

export const reStep1InitReducer = {
  reservationInfoData: {
    reservationInfo: reStep1Info,
    unacceptedList: UnacceptedList,
    cancelPolicy: CancelPolicy,
    priceList: {
      carClass: [
        {
          partnerId: 0,
          carClassId: '',
          time: Time,
          newTimePrice: Time,
          distance: Distance,
          newDistancePrice: Distance,
        },
      ],
      noteList: [
        {
          note: '',
        },
      ],
    },
    reservationPrice: ReservationPrice,
    nightPack: NightPack,
  },
};
export const totalStep = 3;
export const stepTitle = ['予約情報入力', '予約内容確認', '予約完了'];
export const stepDescriptions = ['予約情報入力', '予約内容確認', '予約完了'];
export const PayVaultJS =
  'https://stg-static-content.payment.global.rakuten.com/pv/payvault/V7/7.6/payvault.js';
export const orixHttp = 'https://www.orix-carsharing.com/map/image/ntj/';
export const rakutenCoJp = 'https://point.rakuten.co.jp/';
export const weekDayShort = ['日', '月', '火', '水', '木', '金', '土'];
export const cardTypeOptions = [
  {
    displayText: 'Visa',
    value: 'Visa',
  },
  {
    displayText: 'MasterCard',
    value: 'MasterCard',
  },
  {
    displayText: 'JCB',
    value: 'JCB',
  },
  {
    displayText: 'American Express',
    value: 'American Express',
  },
];
export const inputElementStyles = {
  base: {
    color: 'black',
    border: '1px solid #606060',
    'border-radius': '4px',
    padding: '0 0 0 16px',
    'font-size': '14px',
  },
  valid: {
    color: 'green',
    border: '2px solid green',
    'border-radius': '4px',
    padding: '0 0 0 16px',
  },
  invalid: {
    color: 'red',
    border: '1px solid red',
    'border-radius': '4px',
  },
  focus: {
    color: 'black',
    border: '1px solid #333333',
    'border-radius': '4px',
    padding: '0 0 0 16px',
  },
};
export const bookingConfirmation = 0;
export const reservationChange = 1;
export const detail = 3;
export const rebook = 4;
export const orderInBooking = 0;
export const orderInUsing = 1;
export const orderInUsed = 2;
export const orderInComplete = 9;
export const cancelErrorHelpLink = '';
export const errorHelpLink = 'https://carshare.faq.rakuten.net/s/';
export const beforeApplying = '0';
export const approvalPending = '1';
export const retain = '2';
export const approved = '3';
export const remand = '4';
export const suspensionOfUse = '7';
export const booking = 0;
export const using = 1;
export const used = 2;
export const cancel = 9;
export const reInfoHelpLink = 'https://carshare.faq.rakuten.net/s/';
export const cancelHelpLink = 'https://carshare.faq.rakuten.net/s/';
export const cancelLink = '';
export const receiptDialogLink = '';
export const couponNoAcquiredStatus = 0;
export const couponAcquiredStatus = 1;
export const couponExpiredStatus = 2;
export const couponErrorStatus = 3;
export const couponNoAcquiredStatusText = 'クーポンを獲得する';
export const couponAcquiredStatusText = 'クーポン獲得済み';
export const couponExpiredStatusText = '好評につき、終了しました';
export const couponErrorStatusText = '一時的にこのクーポンをご利用できません';

export const initStatus = 0;
export const successStatus = 200;
export const notSuccessStatus = 400;
export const errorStatus = 500;
export const reservationStatusCode = 'RESERVATION';
export const inUseStatusCode = 'IN_USE';
export const usedStatusCode = 'USED';
export const cancelStatusCode = 'CANCEL';
export const allStatusCode = 'ALL';
export const conditionMessageList = ['すべて', '予約中', '利用済み', '利用中', 'キャンセル済み'];
export const DCPlaceholder = '例）0000 000000 0000';
export const AMEXPlaceholder = '例）0000 000000 00000';
export const DEFTPlaceholder = '例）0000 0000 0000 0000';
export const domain = 'https://carshare.rakuten.co.jp';
export const sourceFormat = 'YYYY/MM/DDTHH:mm';
export const targetFormat = 'YYYY/M/D （ddd） HH:mm';
export const shortFormat = 'M/D (ddd) H:mm';
export const shortTimeFormat = 'YYYY/M/D (ddd) H:mm';
export const shortTimeWithoutSpace = 'YYYY/M/D(ddd)HH:mm';
export const targetDateFormat = 'YYYY/M/D(ddd)';
export const iconList = [{ 10: <OrixIcon /> }, { 20: <CaritecoIcon /> }];
export const cancelMessage = '予約をキャンセルしました。';
export const UsedPriority = {
  rakuten_super_points: 'rakuten_super_points',
  rakuten_cash: 'rakuten_cash',
};
export type UseType = 'noUse' | 'partUse' | 'allUse';
export const UsedType: { noUse: UseType; partUse: UseType; allUse: UseType } = {
  noUse: 'noUse',
  partUse: 'partUse',
  allUse: 'allUse',
};
const CouponsList = [
  {
    code: '',
    key: '',
    name: '',
    discountAmount: 0,
    endDate: '',
    departureDateTime: '',
    returnDateTime: '',
    lowPrice: 0,
    issuedCnt: 0,
    isAutoCoupon: false,
  },
];
export const reCouponsListInitReducer = {
  acquiredCouponList: CouponsList,
  availableCouponCount: 0,
};
export const usableBrandCards = ['Visa', 'MasterCard', 'JCB', 'American Express'];
