type Props = {
  className?: string;
  color?: string;
};

export default function SearchIcon(props: Props) {
  const { className = '', color = 'white' } = props;
  return (
    <span className={className}>
      <svg
        width="15"
        height="14"
        viewBox="0 0 15 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.5 5.5C1.5 7.981 3.5185 10 6 10C8.481 10 10.5 7.981 10.5 5.5C10.5 3.0185 8.481 1 6 1C3.5185 1 1.5 3.0185 1.5 5.5ZM0.5 5.5C0.5 2.4625 2.9625 0 6 0C9.0375 0 11.5 2.4625 11.5 5.5C11.5 8.5375 9.0375 11 6 11C2.9625 11 0.5 8.5375 0.5 5.5ZM10.2236 10.4306C10.4771 10.2131 10.7131 9.97715 10.9306 9.72365L14.5001 13.2926L13.7931 14.0001L10.2236 10.4306Z"
          fill={color}
        />
      </svg>
    </span>
  );
}
