import caritecoIcon from 'icons/svg/cariteco/logo_cariteco.svg';
type Props = {
  className?: string;
};
export default function CaritecoIcon(props: Props) {
  return (
    <span className={props.className}>
      <svg
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask-cariteco"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="38"
          height="38"
        >
          <rect width="38" height="38" rx="4" fill="#C4C4C4" />
        </mask>
        <g mask="url(#mask-cariteco)">
          <rect x="0" y="0" width="38" height="38" fill="url(#pattern-cariteco)" />
        </g>
        <defs>
          <pattern
            id="pattern-cariteco"
            patternContentUnits="objectBoundingBox"
            width="1"
            height="1"
          >
            <use xlinkHref="#image-cariteco" transform="scale(0.0178571)" />
          </pattern>
          <image id="image-cariteco" href={caritecoIcon} width="56" height="56" />
        </defs>
      </svg>
    </span>
  );
}
