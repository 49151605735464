import Request, { HttpMethod } from 'utils/fetch';
import { ErrorResponse, ReCancelReq } from 'ReservationInfo-Types';

export const fetchCancelReservationData = (req: ReCancelReq): Promise<ErrorResponse> =>
  Request(`/reservation/cancel/${req.reservationId}`, {
    method: HttpMethod.put,
    body: req,
  }).then(response => {
    return response;
  });
