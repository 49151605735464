import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { ErrorResponse } from 'ReservationInfo-Types';
import { fetchCancelReservationAsync } from './actions';

export const isFetchingCancelReservation = createReducer<boolean>(false)
  .handleAction([fetchCancelReservationAsync.request], () => true)
  .handleAction(
    [fetchCancelReservationAsync.success, fetchCancelReservationAsync.failure],
    () => false,
  );

export const fetchingCancelReservationSuccess = createReducer<ErrorResponse>(
  {} as ErrorResponse,
).handleAction(fetchCancelReservationAsync.success, (_state, action) => action.payload);

export const fetchingCancelReservationFailure = createReducer<ErrorResponse>(
  {} as ErrorResponse,
).handleAction(fetchCancelReservationAsync.failure, (_state, action) => action.payload);

const reservationInfoReducer = combineReducers({
  isFetchingCancelReservation,
  fetchingCancelReservationSuccess,
  fetchingCancelReservationFailure,
});

export default reservationInfoReducer;
export type ReservationStepState = ReturnType<typeof reservationInfoReducer>;
