type Props = {
  className?: string;
  color?: string;
};
export default function TurnDownIcon(props: Props) {
  const { className, color = '#686868' } = props;
  return (
    <span className={className}>
      <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.0136719" width="1" height="8" fill={color} />
        <rect
          x="0.0136719"
          y="9"
          width="1"
          height="8"
          transform="rotate(-90 0.0136719 9)"
          fill={color}
        />
      </svg>
    </span>
  );
}
