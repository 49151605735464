export const GOOGLE_MAPS_KEY = 'AIzaSyDWWEupfNZ4xLfmiNe3XP3UCYQ5W-qAcsk';
export const API_ERROR_CODE = 400;
export const UNAUTHORIZED_ERROR_CODE = 401;
export const SYSTEM_ERROR_CODE = 500;
export const COUPON_ERROR_CODE = ['R034', 'R035', 'R036'];

export const TEXT_LINK_COLOR = '#1D54A7';
export const TEXT_DANGER_COLOR = '#DF0101';
export const TEXT_BLACK_COLOR = '#000000';
export const TEXT_DISABLE_COLOR = '#898989';
export const TEXT_GRAY_COLOR = '#606060';

export const DISPLAY_FORMAT = 'YYYY/M/D(ddd) HH:mm';
export const DATE_TIME_FORMAT = 'YYYY/MM/DD HH:mm:ss';

export const UPLOAD_FILE_SIZE = 5; //MB

export const DEFAULT_STATION = '東京駅';
export const PLACEHOLDER_STATION = '例）東京駅';

export const HANDING_ADULT_AGE = 18;

export const DEFAULT_MAP_ZOOM = 14;

export const COUPON_ACQUISITION_AUTH_KEY = 'csy94DaC9SgDwxUegg8JZz37HoEudyWr';

export const MAX_ISSUE_COUNT = 20;

export const NORMAL_ICON_Z_INDEX = 1;

export const ACTIVE_ICON_Z_INDEX = 2;

export enum ReservationStatus {
  unavailable = 0,
  available = 1,
  outsideTime = 2,
}
