type Props = {
  color?: string;
};

export default function ChevronUp(props: Props) {
  const { color = 'white' } = props;
  return (
    <svg width="16" height="16" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.60352 10.8965L2.31052 11.6035L8.10352 5.81048L13.8965 11.6035L14.6035 10.8965L8.10352 4.39648L1.60352 10.8965Z"
        fill={color}
      />
    </svg>
  );
}
