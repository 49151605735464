import { createAsyncAction, AppThunk, createAction } from 'typesafe-actions';
import {
  ReservationStepError,
  BackReservationStep1,
  ReservationStep1Response,
  fetchError,
  CouponDetailInfoResponse,
  PriceWithCouponResponse,
  PriceWithCouponRequest,
} from 'ReservationStep1-Types';
import {
  fetchPriceWithCouponPriceData,
  getCouponDetailInfo,
  getReservationStep1,
  getReservationStep1Info,
} from './api';

export const getReservationStep1Async = createAsyncAction(
  'FETCH_GET_REQUEST',
  'FETCH_GET_SUCCESS',
  'FETCH_GET_FAILURE',
)<undefined, BackReservationStep1, ReservationStepError>();

export const getReservationStep1Data = (
  step1Data: BackReservationStep1,
): AppThunk => async dispatch => {
  dispatch(getReservationStep1Async.request());
  const response = getReservationStep1(step1Data);
  dispatch(getReservationStep1Async.success(response));
};
export const fetchReservationStep1InfoAsync = createAsyncAction(
  'FETCH_RESERVATION_STEP_1_INFO_REQUEST',
  'FETCH_RESERVATION_STEP_1_INFO_SUCCESS',
  'FETCH_RESERVATION_STEP_1_INFO_FAILURE',
)<undefined, ReservationStep1Response, fetchError>();

export const fetchReservationStep1Info = (req): AppThunk => async dispatch => {
  dispatch(fetchReservationStep1InfoAsync.request());
  try {
    const response = await getReservationStep1Info(req);
    dispatch(fetchReservationStep1InfoAsync.success(response));
    dispatch(fetchReservationStep1InfoAsync.failure({} as fetchError));
  } catch (error) {
    dispatch(fetchReservationStep1InfoAsync.failure(error as fetchError));
  }
};

export const fetchCouponDetailInfoAsync = createAsyncAction(
  'FETCH_COUPON_DETAIL_INFO_REQUEST',
  'FETCH_COUPON_DETAIL_INFO_SUCCESS',
  'FETCH_COUPON_DETAIL_INFO_FAILURE',
)<undefined, CouponDetailInfoResponse, fetchError>();

export const fetchCouponDetailInfo = (req): AppThunk => async dispatch => {
  dispatch(fetchCouponDetailInfoAsync.request());
  try {
    const response = await getCouponDetailInfo(req);
    dispatch(fetchCouponDetailInfoAsync.success(response));
    dispatch(fetchCouponDetailInfoAsync.failure({} as fetchError));
  } catch (error) {
    dispatch(fetchCouponDetailInfoAsync.failure(error as fetchError));
  }
};

export const fetchPriceWithCouponAsync = createAsyncAction(
  'FETCH_PRICE_WITH_COUPON_REQUEST',
  'FETCH_PRICE_WITH_COUPON_SUCCESS',
  'FETCH_PRICE_WITH_COUPON_FAILURE',
)<undefined, PriceWithCouponResponse, ReservationStepError>();

export const fetchPriceWithCoupon = (req: PriceWithCouponRequest): AppThunk => async dispatch => {
  dispatch(fetchPriceWithCouponAsync.request());
  try {
    const response = await fetchPriceWithCouponPriceData(req);
    dispatch(fetchPriceWithCouponAsync.success(response));
    dispatch(fetchPriceWithCouponAsync.failure({} as ReservationStepError));
  } catch (error) {
    dispatch(fetchPriceWithCouponAsync.failure(error as ReservationStepError));
  }
};

export const setOriginalPage = createAction('ORIGINAL_PAGE')<string>();
export const setStep1PriceWithCouponReqData = createAction('STEP1_PRICE_WITH_COUPON_DATA')<
  PriceWithCouponRequest
>();
export const setPriceWithCouponReqTemporaryData = createAction(
  'STEP1_PRICE_WITH_COUPON_TEMPORARY_DATA',
)<PriceWithCouponRequest>();
export const setIsCouponOneClickLink = createAction('IS_COUPON_ONE_CLICK_LINK')<boolean>();
