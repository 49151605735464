type Props = {
  className?: string;
  isDisable?: boolean;
};
export default function RightSide(props: Props) {
  const { className, isDisable = false } = props;
  return (
    <span className={className}>
      <svg
        width="17"
        height="24"
        viewBox="0 0 17 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.5 12.5H9V7H8V12.5H2.5V13.5H8V19H9V13.5H14.5V12.5Z"
          fill={isDisable ? '#606060' : 'white'}
        />
      </svg>
    </span>
  );
}
