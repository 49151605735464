import { MouseEventHandler } from 'react';

type Props = {
  className?: string;
  onClick?: MouseEventHandler<HTMLSpanElement>;
};

export default function SearchCondition(props: Props) {
  const { className, ...rest } = props;
  return (
    <span className={className} {...rest}>
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="44" height="44" rx="22" fill="#BF0000" />
        <path
          d="M31 12.25H13C11.725 12.25 10.975 13.825 11.8751 14.8L19.7501 23.125V31.75H21.2501L24.25 28.75V27.25V23.125L32.125 14.725C33.025 13.825 32.275 12.25 31 12.25Z"
          fill="white"
        />
      </svg>
    </span>
  );
}
