type Props = {
  className?: string;
};
export default function CarOutLine(props: Props) {
  return (
    <span className={props.className}>
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.25 8.5C3.836 8.5 3.5 8.836 3.5 9.25C3.5 9.664 3.836 10 4.25 10C4.664 10 5 9.664 5 9.25C5 8.836 4.664 8.5 4.25 8.5Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.75 8.5C12.336 8.5 12 8.836 12 9.25C12 9.664 12.336 10 12.75 10C13.164 10 13.5 9.664 13.5 9.25C13.5 8.836 13.164 8.5 12.75 8.5Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.5 11.5H10.5V9.5H6.5V11.5H2.5V7.8025L3.035 7H13.965L14.5 7.8025V11.5ZM7.5 11.5H9.5V10.5H7.5V11.5ZM4.3345 2.5H12.6655L13.302 6H3.698L4.3345 2.5ZM15.332 7.248L14.797 6.4455C14.6825 6.274 14.518 6.152 14.3325 6.078L13.6495 2.321C13.563 1.8455 13.1485 1.5 12.6655 1.5H4.3345C3.8515 1.5 3.437 1.8455 3.3505 2.321L2.6675 6.078C2.482 6.152 2.3175 6.274 2.203 6.4455L1.668 7.248C1.5585 7.4125 1.5 7.6055 1.5 7.8025V11.5C1.5 12.052 1.948 12.5 2.5 12.5V13.5C2.5 14.052 2.948 14.5 3.5 14.5H4C4.552 14.5 5 14.052 5 13.5V12.5H12V13.5C12 14.052 12.448 14.5 13 14.5H13.5C14.052 14.5 14.5 14.052 14.5 13.5V12.5C15.052 12.5 15.5 12.052 15.5 11.5V7.8025C15.5 7.6055 15.4415 7.4125 15.332 7.248Z"
          fill="white"
        />
      </svg>
    </span>
  );
}
