import {
  CancelBookRequest,
  CancelBookResponse,
  ReInfoRequest,
  ReInfoResponse,
  ReListRequest,
  ReListResponse,
  ReservationListError,
} from 'ReservationList-Types';
import { createAsyncAction, AppThunk } from 'typesafe-actions';

import { fetchCancelBookData, fetchReInfoData, fetchReListData } from './api';

export const fetchReListAsync = createAsyncAction(
  'FETCH_LIST_REQUEST',
  'FETCH_LIST_SUCCESS',
  'FETCH_LIST_FAILURE',
)<undefined, ReListResponse, ReservationListError, ReListResponse>();

export const fetchRelist = (req: ReListRequest): AppThunk => async dispatch => {
  dispatch(fetchReListAsync.request());
  try {
    const response = await fetchReListData(req);
    dispatch(fetchReListAsync.success(response));
    dispatch(fetchReListAsync.failure({} as ReservationListError));
  } catch (error) {
    dispatch(fetchReListAsync.failure(error as ReservationListError));
  }
};
export const fetchCancelBookAsync = createAsyncAction(
  'FETCH_CANCEL_REQUEST',
  'FETCH_CANCEL_SUCCESS',
  'FETCH_CANCEL_FAILURE',
)<undefined, CancelBookResponse, ReservationListError>();

export const fetchCancelBook = (req: CancelBookRequest): AppThunk => async dispatch => {
  dispatch(fetchCancelBookAsync.request());
  try {
    const response = await fetchCancelBookData(req);
    dispatch(fetchCancelBookAsync.success(response));
    dispatch(fetchCancelBookAsync.failure({} as ReservationListError));
  } catch (error) {
    dispatch(fetchCancelBookAsync.failure(error as ReservationListError));
  }
};
export const fetchInfoAsync = createAsyncAction(
  'FETCH_INFO_REQUEST',
  'FETCH_INFO_SUCCESS',
  'FETCH_INFO_FAILURE',
)<undefined, ReInfoResponse, ReservationListError>();

export const fetchReInfoBook = (req: ReInfoRequest, id: string): AppThunk => async dispatch => {
  dispatch(fetchInfoAsync.request());
  try {
    dispatch(fetchInfoAsync.success({} as ReInfoResponse));
    const response = await fetchReInfoData(req, id);
    dispatch(fetchInfoAsync.success(response));
    dispatch(fetchInfoAsync.failure({} as ReservationListError));
  } catch (error) {
    dispatch(fetchInfoAsync.failure(error as ReservationListError));
  }
};
