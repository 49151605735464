export const TOP = '/';
export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const STATIONS = '/stations';

// Reservation
export const TERMS_ORIX = '/terms';
export const RESERVATION_STEP1 = '/reservation/step1';
export const RESERVATION_STEP2 = '/reservation/step2';
export const RESERVATION_STEP3 = '/reservation/step3';
export const RESERVATIONS = '/reservations';
export const RESERVATION_VIEW = '/reservation/view';
export const RESERVATION_RECEIPT = '/reservation/receipt';
export const RESERVATION_CHANGE_STEP1 = '/reservation/change/step1';
export const RESERVATION_CHANGE_STEP2 = '/reservation/change/step2';
export const RESERVATION_CHANGE_STEP3 = '/reservation/change/step3';

// Membership
export const IMPORTANT_MATTERS = '/important-matters';
export const MEMBER_STEP1 = '/member/step1';
export const MEMBER_STEP2 = '/member/step2';
export const MEMBER_STEP3 = '/member/step3';
export const MEMBER_STATUS_STOP = '/member/status/stop';
export const MEMBER_STATUS_REVIEW = '/member/status/review';
export const MEMBER_INFO = '/member/info';
export const MEMBER_CHANGE_STEP1 = '/member/change/step1';
export const MEMBER_CHANGE_STEP2 = '/member/change/step2';
export const MEMBER_CHANGE_STEP3 = '/member/change/step3';
export const MEMBER_WITHDRAWAL_STEP1 = '/member/withdrawal/step1';
export const MEMBER_WITHDRAWAL_STEP2 = '/member/withdrawal/step2';
