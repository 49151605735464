import { reservationListInitData } from 'constants/reservationStep';
import { combineReducers } from 'redux';
import {
  CancelBookResponse,
  ReInfoResponse,
  ReListResponse,
  ReservationListError,
} from 'ReservationList-Types';
import { createReducer } from 'typesafe-actions';
import { fetchCancelBookAsync, fetchInfoAsync, fetchReListAsync } from './actions';

export const isFetchingReList = createReducer<boolean>(false)
  .handleAction([fetchReListAsync.request], () => true)
  .handleAction([fetchReListAsync.success, fetchReListAsync.failure], () => false);

export const fetchingReListSuccess = createReducer<ReListResponse>(
  reservationListInitData as ReListResponse,
).handleAction(fetchReListAsync.success, (_state, action) => action.payload);

export const fetchingReListFailure = createReducer<ReservationListError>(
  {} as ReservationListError,
).handleAction(fetchReListAsync.failure, (_state, action) => action.payload);

export const isFetchingCancelBook = createReducer<boolean>(false)
  .handleAction([fetchCancelBookAsync.request], () => true)
  .handleAction([fetchCancelBookAsync.success, fetchCancelBookAsync.failure], () => false);

export const fetchingCancelBookSuccess = createReducer<CancelBookResponse>(
  {} as CancelBookResponse,
).handleAction(fetchCancelBookAsync.success, (_state, action) => action.payload);

export const fetchingCancelBookFailure = createReducer<ReservationListError>(
  {} as ReservationListError,
).handleAction(fetchCancelBookAsync.failure, (_state, action) => action.payload);
export const isFetchingReInfo = createReducer<boolean>(false)
  .handleAction([fetchInfoAsync.request], () => true)
  .handleAction([fetchInfoAsync.success, fetchInfoAsync.failure], () => false);

export const fetchingReInfoSuccess = createReducer<ReInfoResponse>(
  {} as ReInfoResponse,
).handleAction(fetchInfoAsync.success, (_state, action) => action.payload);

export const fetchingReInfoFailure = createReducer<ReservationListError>(
  {} as ReservationListError,
).handleAction(fetchInfoAsync.failure, (_state, action) => action.payload);

const reservationListReducer = combineReducers({
  isFetchingReList,
  fetchingReListSuccess,
  fetchingReListFailure,
  isFetchingCancelBook,
  fetchingCancelBookSuccess,
  fetchingCancelBookFailure,
  isFetchingReInfo,
  fetchingReInfoSuccess,
  fetchingReInfoFailure,
});

export default reservationListReducer;
export type ReservationListState = ReturnType<typeof reservationListReducer>;
